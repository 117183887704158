const formatTeamMemberLegacyFullName = (teamMember) =>
  teamMember?.title.replace('<br />', ' ')

const combineTeamMemberFirstAndLastName = (teamMember) =>
      [ teamMember.firstName, teamMember.lastName ].join(' ')

/**
 * Gets the full name of a team member on the Team page. If there's a
 * firstName field filled, we use the concatentation of the first and l
 * last name. Otherwise, we fall back to the legacy 'title' field, which
 * was used as the combined first and last name in the old team page.
 *
 * @param {object} teamMember - The team member object
 * @return {string} The team member's full name
 */
export const getTeamMemberFullName = (teamMember) =>
  teamMember?.firstName ?
      combineTeamMemberFirstAndLastName(teamMember) :
      formatTeamMemberLegacyFullName(teamMember)
