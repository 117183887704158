import clsx from 'clsx'
import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'

import {
  Heading,
  Container,
  Paragraph,
  SubHeading,
  GridContainer,
  SectionContainer,
} from '../index'

import useIntroEnded from '../../hooks/useIntroEnded'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const HeroPage = ({ headline, title, tagline }) => {
  const headingRef = useRef()
  const subHeadingRef = useRef()
  const paragraphRef = useRef()
  const tl = useRef()

  const { introEnded } = useIntroEnded()

  useEffect(() => {
    tl.current = gsap
      .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
      .set(paragraphRef?.current, { opacity: 0, y: 10 })
  }, [])

  useEffect(() => {
    if (introEnded) {
      tl.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .call(() => subHeadingRef?.current?.animateIn(), null, 'init+=.4')
        .call(() => headingRef?.current?.animateIn(), null, 'init+=.2')
        .to(paragraphRef?.current, { opacity: 1, y: 0 }, 'init+=.8')
    }
  }, [introEnded])

  const hLengthMax = useLengthOfLongestWord(title)

  return (
    <SectionContainer className='mb-0 md:mb-150 hero-pt'>
      <Container>
        <GridContainer className='flex justify-center'>
          <div className='flex col-span-4 justify-center md:col-span-7 md:col-start-1'>
            <div className='flex flex-col pb-10'>
              <SubHeading
                text={headline}
                className='place-self-start pb-40 md:pb-35'
                ref={subHeadingRef}
                buildUpAnimation={true}
              />

              <Heading
                semantics='h2'
                text={title}
                className={clsx(
                  'md:text-10xl break-words text-5xl -tracking-4 uppercase md:-ml-10  md:text-left',
                  hLengthMax > 10 ? 'h1-scale-down-A' : hLengthMax > 8 && 'h1-scale-down-B'
                )}
                indent='17'
                buildUpAnimation={true}
                ref={headingRef}
              />

              <Paragraph text={tagline} className='pt-40 md:pt-40 place-self-center md:w-400' ref={paragraphRef} />
            </div>
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default HeroPage
