/**
 * WordPress Contact Form 7 Utilities
 */

/**
 * Matches a valid CF7 endpoint URL in order to extract its CF7 form ID.
 * This allows us to continue to use the full endpoint URL's rather than
 * have to additionally supply the form ID's as environment variables.
 */
const ENDPOINT_REGEXP = /https?:\/\/(.+)\/wp-json\/contact-form-7\/v1\/contact-forms\/(\d+)\/feedback/;

/**
 * Extracts the CF7 form ID from its valid REST endpoint.
 *
 * @param {string} url - the valid CF7 REST endpoint URL
 * @returns {number} the CF7 numeric form ID
 */
const getCF7FormIdFromEndpointUrl = (url) => {
  const matches = url.match(ENDPOINT_REGEXP);
  return matches[2];
}

/**
 * The total count of the number of tags that have been generated
 * on the current page. This ensures the same unit tag is never sent twice.
 */
let unitTagGlobalCount = 0;

/**
 * Generates a Contact Forms 7 unit tag to be sent along with the
 * form request. This is required in the latest version of CF7.
 *
 * @param {number} formId - the CF7 form ID
 * @returns the unit tag to be sent with the CF7 request
 *
 * @see https://github.com/rocklobster-in/contact-form-7/issues/1366
 */
export const generateCF7UnitTagFromFormId = (formId) => {
  unitTagGlobalCount += 1;
  return `wpcf7-f${formId}-o${unitTagGlobalCount}`;
};

/**
 * Convenience method to generate a CF7 unit tag directly from a valid
 * CF7 form REST endpoint URL.
 *
 * @param {string} url - the CF7 form endpoint URL
 * @returns the unit tag to be sent with the CF7 request
 *
 * @see https://github.com/rocklobster-in/contact-form-7/issues/1366
 */
export const generateCF7UnitTagFromEndpointUrl = (url) =>
  generateCF7UnitTagFromFormId(getCF7FormIdFromEndpointUrl(url));

/**
 * The form field name for the unit tag that CF7 expects to be sent with
 * form requests.
 *
 * @see https://github.com/rocklobster-in/contact-form-7/issues/1366
 */
export const CF7_UNIT_TAG_FIELD_NAME = '_wpcf7_unit_tag';
