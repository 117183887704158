import { useState } from 'react'
import { Input, Button } from '../../index'
import { EMAIL_REGEX } from '../../../utils/siteConfig'

/**
 * Valid Form Criterias
 * 1. All values must be non-empty values &
 * 2. Email must match a regex
 */
const ContactForm = ({ className = '' }) => {
  const defaultFormFields = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  }

  // Only list Required fields below for validation check
  const defaultValidFields = {
    name: false,
    email: false,
    phone: false,
    subject: false,
    message: false,
  }

  const [formFields, setFormFields] = useState(defaultFormFields)
  const [validFields, setValidFields] = useState(defaultValidFields)
  const [submitAttempt, setFormSubmitAttempt] = useState(false)
  const [isFormValid, setFormValid] = useState(false)
  const [submitSuccessMessage, setFormSubmitSuccessMessage] = useState('')
  const [submitErrorMessage, setFormSubmitErrorMessage] = useState('')
  const [submitSpinner, setFormSubmitSpinner] = useState(false)

  const handleFieldOnChange = event => {
    const { name } = event?.target
    const { value } = event?.target

    switch (name) {
      case 'name':
      case 'phone':
      case 'subject':
      case 'message':
        handleInputOnChange(name, value, true)
        break

      case 'email':
        handleEmailInputOnChange(name, value, true)
        break

      default:
        break
    }
  }

  // #1 Save Non-empty values on FormState
  // #2 Update their validity on ValidFields State
  const handleInputOnChange = (name, value, isRequired) => {
    const newFormState = { ...formFields, [name]: value }
    setFormFields(newFormState)

    // Only update validity state of Required field based on input length
    if (isRequired) {
      const isValidField = !!value?.length
      const newValidFields = { ...validFields, [name]: isValidField }
      setValidFields(newValidFields)
      checkFormValidity(newValidFields)
    }
  }

  const handleEmailInputOnChange = (name, value, isRequired) => {
    const newFormState = { ...formFields, [name]: value }
    setFormFields(newFormState)

    // Only update validity state of Required field based on
    // 1. Input length &
    // 2. Formatting
    if (isRequired) {
      const isValidLength = !!value?.length
      const isValidFormat = value?.match(EMAIL_REGEX) !== null
      const isValidEmail = isValidLength && isValidFormat

      const newValidFields = { ...validFields, [name]: isValidEmail }
      setValidFields(newValidFields)
      checkFormValidity(newValidFields)
    }
  }

  // Check for entire form's validity
  const checkFormValidity = newValidFields => {
    let isFormValid = true // optimistic checks
    for (const [key, value] of Object.entries(newValidFields)) {
      isFormValid = isFormValid && !!value
    }

    setFormValid(isFormValid)
  }

  const handleSubmit = event => {
    console.log('@HandleSubmit')
    event.preventDefault()

    const CF7_ENDPOINT = process.env.NEXT_PUBLIC_CF7_ENDPOINT

    setFormSubmitAttempt(true)
    setFormSubmitSuccessMessage('')
    setFormSubmitErrorMessage('')

    if (isFormValid) {
      setFormSubmitSpinner(true)

      const formdata = new FormData()
      formdata.append('name', formFields.name)
      formdata.append('email', formFields.email)
      formdata.append('phone', formFields.phone)
      formdata.append('subject', formFields.subject)
      formdata.append('message', formFields.message)

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      }

      fetch(CF7_ENDPOINT, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setFormSubmitSpinner(false)

          if (result.status == 'mail_sent') {
            setFormSubmitSuccessMessage(result.message)
          } else {
            setFormSubmitErrorMessage(result.message)
          }
        })
        .catch(error => {
          console.log('Submisison Error:', error)
          setFormSubmitSpinner(false)
          setFormSubmitErrorMessage('Internal server error, try later.')
        })
    }
  }

  return (
    <form
      name='contactForm'
      onSubmit={handleSubmit}
      // className={`${className}`}
      className={`${className} grid grid-cols-1 md:grid-cols-2 md:px-80 md:gap-x-50 gap-y-20 md:gap-y-40`}
    >
      <Input
        label='Name'
        name='name'
        id='name'
        type='text'
        value={formFields?.name}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.name && submitAttempt) ? 'true' : undefined}
      />

      <Input
        label='Email Address'
        name='email'
        id='email'
        type='text'
        value={formFields?.email}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.email && submitAttempt) ? 'true' : undefined}
      />

      <Input
        label='Phone Number'
        name='phone'
        id='phone'
        type='number'
        value={formFields?.phone}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.phone && submitAttempt) ? 'true' : undefined}
      />

      <Input
        label='Subject'
        name='subject'
        id='subject'
        type='text'
        value={formFields?.subject}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.subject && submitAttempt) ? 'true' : undefined}
      />

      <Input
        className='col-span-full md:row-start-3'
        label='Message'
        name='message'
        id='message'
        type='text'
        value={formFields?.message}
        onChange={handleFieldOnChange}
        rows='5'
        textarea='true'
        invalid={!!(!validFields?.message && submitAttempt) ? 'true' : undefined}
      />

      <div className='mt-15 md:row-start-4 md:mt-0'>
        {/* {!submitSpinner ? (
          <Button text='Send Message' name='send-message'/>
        ) : null} */}

        {/* Form Submit Loader */}
        {/* {submitSpinner ? <Button text='Send Message' icon='loader' /> : null} */}

        <Button
          text='Send Message'
          icon={submitSpinner ? 'loader' : 'arrow'}
          name='send-message'
          disabled={submitSpinner} // disable click & hover effects while n/w request is being made
        />

        {/* Generic Error Message */}
        {submitAttempt && !isFormValid ? (
          <p className='absolute mt-20 text-red-500 text-xs-B -tracking-3 md:mt-40 md:text-2xl-C'>
            One or more fields have an error. Please check and try again.
          </p>
        ) : null}

        {/* Form Success message */}
        {submitSuccessMessage ? (
          <p className='absolute mt-20 text-green-500 text-xs-B -tracking-3 md:mt-40 md:text-2xl-C'>
            {submitSuccessMessage}
          </p>
        ) : null}

        {/* Form Error message */}
        {submitErrorMessage ? (
          <p className='absolute mt-20 text-red-500 text-xs-B -tracking-3 md:mt-40 md:text-2xl-C'>
            {submitErrorMessage}
          </p>
        ) : null}
      </div>
    </form>
  )
}

export default ContactForm
