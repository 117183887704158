import { useEffect, useState } from 'react'
import { GridContainer, SectionContainer, Container } from '../index'

import HeroPage from '../heroPage/HeroPage'
import MediaImage from '../mediaImage/MediaImage'
import ContentText from '../contentText/ContentText'
import MediaGallery from '../mediaGallery/MediaGallery'
import ContentTwoImages from '../contentTwoImages/ContentTwoImages.jsx'
import MediaVideo from '../mediaVideo/MediaVideo'
import ThreeByThreeGrid from '../threeByThreeGrid/ThreeByThreeGrid'
import Billboard from '../billboard/Billboard'
import ContentMediaText from '../contentMediaText/ContentMediaText.jsx'
import Cta from '../cta/Cta'

const PageBuilder = ({ data, fieldPrefix = 'Page_Signaturepagefields_ContentBuilder_' }) => {
  const [builtComponents, setBuiltComponents] = useState(null)

  // Wrapping the mapping function & switch case inside useEffect to minimize redundant re-renders
  useEffect(() => {
    let builtComponents = data?.map((element, index) => {
      const componentName = element?.fieldGroupName?.replace(fieldPrefix, '')

      switch (componentName) {
        case 'Hero':
          return (
            <HeroPage
              key={index}
              title={element?.title}
              headline={element?.headline}
              tagline={element?.tagline}
            />
          )

        case 'MediaImage':
          return (
            <MediaImage
              key={index}
              image={element?.image}
              animate={element?.animate}
              variant={element?.variant}
            />
          )

        case 'RichText':
          return <ContentText key={index} text={element?.text} />

        case 'MediaGallery':
          return <MediaGallery key={index} items={element?.items} />

        case 'Contenttwoimages':
          return (
            <ContentTwoImages
              key={index}
              imageLeft={element.imageLeft}
              imageRight={element.imageRight}
              captionLeft={element.captionLeft}
              captionRight={element.captionRight}
            />
          )

        case 'MediaVideo':
          return (
            <MediaVideo key={index} videoUrl={element?.video} posterImage={element?.posterImage} />
          )

        case 'ItemsList':
          return (
            <ThreeByThreeGrid
              key={index}
              benefitsArray={element?.items}
              backgroundColorSection={false}
            />
          )

        case 'Billboard':
          return (
            <Billboard
              key={index}
              headline={element?.headline}
              description={element?.description}
              showCta={element?.showCta}
              cta={element?.cta}
            />
          )

        case 'TwoColumnsMedia':
          return (
            <ContentMediaText
              key={index}
              headline={element.headline}
              title={element.title}
              description={element.description}
              image={element.image}
              imageCaption={element.imageCaption}
              imageAlignment={element.imageAlignment}
            />
          )

        case 'Cta':
          return (
            <SectionContainer key={index} className='mb-80 md:mb-150'>
              <Container>
                <GridContainer>
                  <Cta cta={element?.cta} className='flex col-span-full justify-center' />
                </GridContainer>
              </Container>
            </SectionContainer>
          )

        default:
          return null
      }
    })

    setBuiltComponents(builtComponents)
  }, [data])

  return builtComponents
}

export default PageBuilder
