import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Cta,
  Dash,
  Heading,
  Paragraph,
  Container,
  SubHeading,
  CustomImage,
  SectionContainer,
} from '../index'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MediaGridText = ({
  imageA,
  heading,
  headingClassName,
  indent,
  subHeading,
  description,
  cta,
  imagePosition = ''
}) => {
  const gridRef = useRef()
  const q = gsap.utils.selector(gridRef)
  const tl = useRef()

  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: gridRef?.current,
              scrub: true,
              invalidateOnRefresh: true,
            },
          })
          .fromTo(
            q('.imageB'),
            {
              yPercent: 25,
            },
            {
              yPercent: -75,
            }
          )

        return function () {
          tl?.current?.kill()
          gsap.set(q('.imageB'), { clearProps: true })
        }
      },
    })
  }, [])

  return (
    <SectionContainer className='bg-background'>
      <Container className='pb-0 pt-60 md:pt-0'>
        <div ref={gridRef}>
          <div className='md:grid md:gap-20 md:grid-flow-row-dense md:grid-cols-9'>
            <div className={`md:col-span-4.5 ${imagePosition == 'left' ? 'md:col-start-5' : 'md:col-start-auto'}`}>
              {/* Left - Copy */}
              <CopyComponent
                heading={heading}
                headingClassName={headingClassName}
                subHeading={subHeading}
                description={description}
                cta={cta}
                indent={indent}
                imagePosition={imagePosition}
              />
            </div>
            <div className={`md:col-span-4.5 ${imagePosition == 'left' ? 'md:col-start-0' : 'md:col-start-auto'}`}>
              {/* Right - Pair of 2 images */}
              <GridImages imageA={imageA} />
            </div>
          </div>
        </div>
      </Container>
    </SectionContainer>
  )
}

// export default MediaGridText;
export default React.memo(MediaGridText)

const GridImages = ({ imageA }) => (
  <div className='col-span-4 -mx-16 md:col-span-3.5 md:-mr-50 md:mr-0'>
    {/* IMAGE A Hides in mobile view */}
    <CustomImage
      className='w-full h-auto bg-image md:block'
      src={imageA.sourceUrl}
      alt={imageA.altText}
      aspectRatio='aspect-w-3 aspect-h-4'
      sizes='(max-width: 768px) 100vw, 50vw'
    />
  </div>
)

const CopyComponent = ({ heading, headingClassName, indent, subHeading, description, cta, imagePosition = '' }) => {
  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <div className='col-span-4 row-start-1 pb-42 md:col-span-5 md:col-start-4 md:row-start-auto md:pb-30 md:pl-65 md:pt-180'>
      <SubHeading text={subHeading} className='pb-40 md:pb-35 md:pt-20' onScrollAnimation={true} />

      <div className='mb-40 md:mb-70'>
        <Heading
          semantics='h2'
          text={heading}
          className={clsx(
            `relative z-10 text-4xl-D -tracking-4 uppercase ${imagePosition == 'left' ? 'md:text-7xl' : 'md:text-8xl-A'} md:-tracking-8`,
            hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B',
            headingClassName
          )}
          indent={indent}
          onScrollAnimation={true}
        />
      </div>

      <div className={clsx('flex items-start', 'mb-10 pr-16 md:ml-0')}>
        <Dash variation='short' className='hidden pt-10 text-primary-dark md:block md:mr-85' />

        <div>
          <Paragraph text={description} className='paragraph mb-40 md:mb-80 md:pr-120' />

          {cta && <Cta cta={cta} />}
        </div>
      </div>
    </div>
  )
}
