// import clsx from 'clsx';
import {
  Heading,
  Container,
  Paragraph,
  SubHeading,
  ContactForm,
  GridContainer,
  SectionContainer,
} from '../index'

const Enquiry = ({ heading, subHeading, noteSubHeading, noteDescription, formComponent }) => {
  return (
    <SectionContainer className='flex flex-col items-center justify-center py-80 bg-background md:pb-180 md:pt-130'>
      <Container>
        <div className='md:pl-80'>
          <SubHeading text={subHeading} className='mb-40 md:mb-35' />

          <div className='pb-60 md:pb-130'>
            <Heading
              semantics='h2'
              text={heading}
              className='text-4xl-D -tracking-4 uppercase md:text-8xl-A'
              indent='20'
            />
          </div>
        </div>

        <GridContainer>
          <div className='col-span-4 md:col-span-7'>
            {/* <ContactForm /> */}
            {formComponent}
          </div>

          <div className='col-span-4 mt-70 md:col-span-2 md:col-start-7 md:mt-0'>
            <SubHeading text={noteSubHeading} />
            <Paragraph
              semantics='p'
              text={noteDescription}
              overrideClassName='mt-16 w-3/5 text-xs-B -tracking-3 md:mt-40 md:w-full md:text-2xl-C'
            />
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default Enquiry
