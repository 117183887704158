import { Transition, SwitchTransition } from 'react-transition-group'
import { useRouter } from 'next/router'

const duration = 250

const defaultStyle = {
  transition: `opacity ${duration}ms cubic-bezier(0, 0, 0.2, 1)`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1, transitionDelay: '100ms' },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

export default function PageTransition({ children, location }) {
  const router = useRouter()
  const { asPath } = router

  const onEnter = to => {
    window.scrollTo(0, 0)
  }

  return (
    <SwitchTransition>
      <Transition key={asPath} timeout={duration} onEnter={onEnter} unmountOnExit={true}>
        {state => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </SwitchTransition>
  )
}
