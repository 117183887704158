import clsx from 'clsx'
import Image from 'next/image'
import {
  Heading,
  SubHeading,
} from '../index'

import placeholderImage from '../../../public/images/team-placeholder.jpg'

import { getTeamMemberFullName } from '../../utils/team'

const TeamGalleryCard = ({ item, onClick }) => {
  const { image } = item;

  const headingText = getTeamMemberFullName(item)
  const subHeadingText = item.jobTitle

  return (
    <div
      className={clsx(
        'group',
        'relative h-auto',
        'cursor-pointer',
        'w-1/3 min-w-auto',
        'md:w-1/4',
        'lg:w-1/6',
        'relative',
      )}
      onClick={onClick}
    >
      <div className="mb-8 ml-8 md:mb-16 md:ml-16">
        <figure
          className={clsx('w-full h-auto aspect-w-1 aspect-h-1')}
        >
          <Image
            src={image?.sourceUrl || placeholderImage}
            alt={image?.altText}
            data-preload={true}
            layout='fill'
            placeholder={placeholderImage}
            className={clsx('w-full h-auto object-cover object-center')}
            sizes='(max-width: 768px) 33vw, (max-width: 1024px) 25vw, 200px'
          />
        </figure>

        <div className={clsx(
          'inset-0 absolute',
          'md:ml-16 md:mb-16',
          'px-16 py-16',
          'opacity-0',
          'group-hover:block transition-opacity duration-500 group-hover:opacity-100 group-hover:bg-black/50'
        )}>
          <Heading
            semantics='h3'
            text={headingText}
            className={clsx(
              'text-primary',
              '-tracking-5.5',

              'text-2xl-A',
              'md:text-2xl-A',
              'lg:text-3xl',
            )}
          />
          <Heading
            semantics='h4'
            text={subHeadingText}
            className={clsx(
              'mt-4',

              'text-primary',
              '-tracking-5.5',

              'text-base',
              'md:text-1xl',
              'lg:text-2xl',

              // Hide on mobile:
              'hidden',
              'md:block',
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default TeamGalleryCard
