import clsx from 'clsx'
import {
  Heading,
  Paragraph,
  SubHeading,
  CustomImage,
  SocialIcons,
  BackgroundTintLayer,
  // SectionContainer,
} from '../index'
import Styles from './Modal.module.scss'
import MenuClose from '../../../public/icons/menu-close.svg'
import { useEffect, useState } from 'react'

// BIO Description Modal
const Modal = ({
  isModalActive,
  image,
  heading,
  subHeading,
  description,
  socialLinks,
  closeModal,
  backgroundColor,
  opacity = 'opacity-60',
  fullWidth = false,
  children,  
}) => {
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    if (!isModalActive) {
      setHidden(true)
    } else {
      const timer = setTimeout(() => {
        setHidden(false)
      }, 300)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isModalActive])

  return (
    <div
      className={clsx(
        'fixed z-40 left-0 top-0 w-screen h-full bg-secondary md:bg-transparent',
        isModalActive && 'p-16 md:p-0 items-center justify-center md:flex ',
        !isModalActive && 'hidden'
      )}>
      {/* Modal Background Tint */}
      <BackgroundTintLayer
        className='hidden md:block'
        onClick={closeModal}
        active={isModalActive}
        backgroundColor={backgroundColor}
        opacity={opacity} // do not use 70 here - becomes much darker than mega-menu
      />

      {/* Modal */}
      <div
        className={clsx(
          Styles.modal,
          fullWidth && Styles.fullWidth,
          `transition duration-300`,
          hidden ? 'opacity-0' : 'opacity-100',
          'bg-background'
        )}
        onClick={event => event.stopPropagation()}
        role='dialog'>
        {/* Close button */}
        <button
          onClick={closeModal}
          aria-label='Close'
          className={clsx(
            Styles.button,
            'absolute z-50 right-30 top-30 md:right-20 md:top-20',
            'flex items-center justify-center',
            'rounded-full focus-visible:outline-black focus:outline-none cursor-pointer',
            'w-32 h-32 md:w-38 md:h-38',
            'text-primary',
            'h-contrast'
          )}>
          <MenuClose className='w-14 h-14 fill-current md:w-14 md:h-14' />
        </button>

        <CustomImage
          src={image?.sourceUrl}
          alt={image?.altText}
          //aspectRatio='aspect-w-1 aspect-h-1 md:aspect-w-5 md:aspect-h-12'
          className={clsx(
            'z-40 flex w-full h-auto bg-background',
            fullWidth ? ' md:w-3/6' : ' md:w-2/6'
          )}
        />

        <div
          className={clsx(
            'flex flex-1 flex-col md:place-self-start',
            'ml-12 pt-20 md:ml-74 md:mr-74  md:pt-50 md:w-4/6'
          )}>
            { children ? children :
              <>
                <SubHeading text={subHeading} className='pb-20' />

                <Heading
                  semantics='h2'
                  text={heading}
                  className='pb-20 text-3xl-B -tracking-5.5 uppercase md:pb-30 md:text-5xl md:-tracking-4'
                />
                <div className='pb-20 md:hidden'>
                  <SocialIcons links={socialLinks} />
                </div>

                <Paragraph
                  text={description}
                  overrideClassName='pb-100 md:pb-80 text-base-A -tracking-3 text-primary-dark'
                />

                <div className='absolute z-50 bottom-20 right-20 hidden md:block'>
                  <SocialIcons links={socialLinks} />
                </div>
              </>
            }
        </div>

        {/* Gradient layer at bottom of modal in the mobile-view */}
        <div className={Styles.modalGradient}></div>
      </div>
    </div>
  )
}

export default Modal
