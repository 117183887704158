// TODO: Fix Animation issue here
import clsx from 'clsx'

import { CopyGroup } from '../index'
import Styles from './ticTacToe.module.scss'

const TicTacToe = ({ gridItems, ref }) => {
  const items = gridItems?.length

  return (
    <div
      className={clsx(
        'bg-red-100',
        Styles.customGrid,
        items <= 3 && Styles.threeItems,
        items == 4 && Styles.fourItems,
        (items == 5 || items == 6) && Styles.fiveOrSixItems,
        items >= 7 && Styles.sevenOrMoreItems
      )}
      ref={ref}
    >
      {gridItems?.map((i, index) => (
        <CopyGroup
          key={index}
          index={index + 1}
          className={clsx('col-span-4 pr-20 py-40 md:col-span-3 md:px-100 md:py-70')}
          heading={i?.title}
          description={i?.description}
          onScrollAnimationTrigger={ref}
        />
      ))}
    </div>
  )
}

export default TicTacToe
