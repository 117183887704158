import clsx from 'clsx'
import { useState } from 'react'

import {
  CustomImage,
  VideoGridModal,
  SectionContainer,
  Container,
  SubHeading,
  Heading,
} from '../index'

import s from './VideoGrid.module.scss'

const VideoGrid = ({ items }) => {
  const [isVideoPlayerEnabled, setIsVideoPlayerEnabled] = useState(false)
  const [videoPlayerUrl, setVideoPlayerUrl] = useState('')
  const [modalData, setModalData] = useState({})
  const [currentVideo, setCurrentVideo] = useState(0)
  const lastVideoIndex = items?.length - 1

  const setNextVideo = () => {
    let nextIndex = currentVideo + 1
    if (nextIndex > lastVideoIndex) {
      nextIndex = 0
    }

    const nextModalData = items[nextIndex]
    setVideoPlayerUrl(nextModalData?.youtubeUrl)
    setModalData(nextModalData)
    setCurrentVideo(nextIndex)
  }

  const setPrevVideo = () => {
    let prevIndex = currentVideo - 1
    if (prevIndex < 0) {
      prevIndex = lastVideoIndex
    }

    const nextModalData = items[prevIndex]
    setVideoPlayerUrl(nextModalData?.youtubeUrl)
    setModalData(nextModalData)
    setCurrentVideo(prevIndex)
  }

  return (
    <SectionContainer className={clsx('py-0 md:py-100', s.videoGrid)}>
      <Container>
        <ul className={clsx(s.gridItems, '')}>
          {items &&
            items.map(({ title, youtubeUrl, appUrl, image, textColumn1, textColumn2 }, index) => {
              return (
                <li
                  className={clsx(s.gridItem, 'cursor-pointer')}
                  key={index}
                  onClick={() => {
                    setVideoPlayerUrl(youtubeUrl)
                    setModalData({ title, appUrl, textColumn1, textColumn2 })
                    setCurrentVideo(index)
                    setIsVideoPlayerEnabled(true)
                  }}>
                  <div className={clsx(s.image, 'mb-20')}>
                    <CustomImage
                      image={image}
                      aspectRatio='aspect-w-16 aspect-h-9'
                      imageClassName='bg-image'
                    />
                  </div>
                  <SubHeading
                    text={index + 1 < 10 ? `0${index + 1}` : index + 1}
                    className='pb-20 md:pb-35'
                  />

                  <Heading
                    semantics='h3'
                    text={title}
                    className={clsx('mb-40 text-2xl-F -tracking-5.5 uppercase md:text-3xl')}
                  />
                </li>
              )
            })}
        </ul>

        <VideoGridModal
          id='gridCareersVideo'
          isModalActive={isVideoPlayerEnabled}
          onClose={() => setIsVideoPlayerEnabled(false)}
          onClickNext={() => setNextVideo()}
          onClickPrev={() => setPrevVideo()}
          url={videoPlayerUrl}
          modalData={modalData}
        />
      </Container>
    </SectionContainer>
  )
}

export default VideoGrid
