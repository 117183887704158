import clsx from 'clsx'
import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import { Container, Cta, Paragraph, Heading } from '../index'

import { EASE } from '../../utils'

import s from './PlanroomText.module.scss'

const PlanroomText = ({ title, text, cta, className = '' }) => {
  const sectionRef = useRef()
  const q = gsap.utils.selector(sectionRef)
  const tl = useRef()

  useEffect(() => {
    setTimeout(() => {
      let items = [q(`.${s.title}`), q(`.${s.text}`)]
      cta && items.push(q(`.${s.cta}`))

      tl.current = gsap
        .timeline({
          defaults: { ease: EASE.expoInOut, overwrite: 'auto' },
          scrollTrigger: {
            trigger: sectionRef?.current,
            start: '0% 85%',
            end: '100% 0',
          },
        })
        .addLabel('init')
        .fromTo(
          items,
          {
            y: '8.5rem',
          },
          {
            y: '0rem',
            duration: 1,
            stagger: 0.15,
          },
          'init'
        )
        .fromTo(
          items,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.333,
            stagger: 0.15,
          },
          'init'
        )
        .set(items, {
          clearProps: true,
        })

      return function () {
        tl?.current?.kill()
        gsap.set(items, { clearProps: true })
      }
    }, 100)
  }, [])

  return (
    <section ref={sectionRef} className={'pb-65 pt-70 md:pb-220 md:pt-180'}>
      <Container>
        <div className={'grid md:grid-cols-9 md:gap-20 md:p-0'}>
          <Heading
            text={title}
            semantics='h2'
            className={clsx(s.title, 'mt-5 md:col-span-2.5 md:col-start-1.5 md:row-span-1')}
          />
          <Paragraph
            text={text}
            className={clsx(s.text, 'mt-10 md:col-span-2.5 md:col-start-4.5 md:row-span-1')}
            semantics='div'
          />
          {cta && (
            <Cta cta={cta} className={clsx(s.cta, 'md:col-span-2 md:col-start-4.5 md:row-span-1')}>
              {cta.label}
            </Cta>
          )}
        </div>
      </Container>
    </section>
  )
}

export default PlanroomText
