import { Link } from '../index'

// Dark/Teal Logo - Primary - Header
import McAlvainLogoPrimary from '../../../public/svg/mc-alvain-logo-primary.svg'
// White Logo - Secondary - Footer
import McAlvainLogoSecondary from '../../../public/svg/mc-alvain-logo-secondary.svg'

const Logo = ({ primary = true, onClick, tabIndex }) => (
  <Link href={'/'}>
    <a onClick={onClick} tabIndex={tabIndex}>
      {primary ? (
        <McAlvainLogoPrimary className='w-140 h-auto md:w-140' style={{ width: '20rem' }} />
      ) : (
        <McAlvainLogoSecondary className='w-130 h-auto md:w-150' />
      )}
      <span className='sr-only'>Mc Alvain Companies a Big-D Company </span>
    </a>
  </Link>
)

export default Logo
