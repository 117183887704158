import clsx from 'clsx'
import { Icon, Link } from '../index'
import Styles from './Button.module.scss'

const Button = ({
  text,
  href,
  target = '_self',
  name = '',
  buttonClickHandler = () => {},
  className = '',
  buttonColor = 'primary',
  icon = 'arrow', // Arrow-right by default -
  fullWidthMobile = false,
  disabled = false,
  invalid = false,
  full = false,
  generic = false,
}) => {
  const isBlankTarget = target == '_blank'
  const buttonPrimary = buttonColor === 'primary'

  return (
    <div
      className={clsx(
        !disabled && Styles.button, // hover animation
        generic && Styles.generic,
        'inline-flex text-lg -tracking-1.5 border',
        // Primary
        buttonPrimary && !generic && 'hover:text-accent border-accent',
        generic && 'border-image',
        buttonPrimary && !disabled && 'text-primary',
        // Secondary button
        !buttonPrimary && 'hover:text-primary text-secondary bg-primary border-primary',
        !buttonPrimary && buttonColor !== 'none' && !disabled && Styles.BgTransparent,
        //
        buttonColor === 'none' && 'hover:text-primary',
        buttonColor === 'none' && !disabled && Styles.BgTransparent,
        fullWidthMobile && 'w-full justify-center md:w-auto',
        full && 'w-full',
        invalid && 'border-red-500',
      )}>
      {href ? (
        <Link href={href} prefetch={false}>
          <a
            className={`inline-flex w-full justify-center items-center px-20 py-15 focus:outline-none focus-visible:outline-black ${className}`}
            target={target}
            rel={isBlankTarget ? 'noopener noreferrer' : ''}>
            {text}
            <Icon className='ml-12' name={icon} />
          </a>
        </Link>
      ) : (
        generic ? (
          <div
            disabled={disabled}
            name={name}
            className={`inline-flex w-full justify-center items-center px-20 py-15 focus:outline-none focus-visible:outline-black ${className}`}
            onClick={buttonClickHandler}>
            {text}
            <Icon className='ml-12' name={icon} />
          </div>
        ) : (
          <button
            disabled={disabled}
            name={name}
            className={`inline-flex w-full justify-center items-center px-20 py-15 focus:outline-none focus-visible:outline-black ${className}`}
            onClick={buttonClickHandler}>
            {text}
            <Icon className='ml-12' name={icon} />
          </button>
        )
      )}
    </div>
  )
}

export default Button
