import { Heading, Container, Cta, SubHeading, GridContainer, SectionContainer } from '../index'

const Billboard = ({ headline, description, showCta, cta }) => (
  <SectionContainer className='mb-80 md:mb-150'>
    <Container>
      <GridContainer>
        <div className='col-span-full'>
          <SubHeading text={headline} className='mb-40 md:mb-35' onScrollAnimation={true} />

          <Heading
            semantics='h3'
            text={description}
            className='text-3xl-F -tracking-4 uppercase md:text-7xl'
            buildUpAnimation={'lines'}
            onScrollAnimation={true}
          />
        </div>
      </GridContainer>

      {showCta && (
        <GridContainer>
          <Cta cta={cta} className='col-span-full mt-40 md:mt-80' />
        </GridContainer>
      )}
    </Container>
  </SectionContainer>
)

export default Billboard
