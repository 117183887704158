import clsx from 'clsx'
import Image from 'next/image'
import { IMG_LAZY_LOAD_THRESHOLD } from '../../../utils/siteConfig'
import Styles from './GridItem.module.scss'
import { animated, useSpring } from '@react-spring/web'

import { Heading, SubHeading, CustomImage, LinkWrapper } from '../../index'

// Individual Project Component
const GridItem = ({ item, index, tall, style, isViewportMobile, outAnimation }) => {
  const styles = useSpring({
    transform: outAnimation ? 'translate3d(0%,0,0)' : 'translate3d(-100%,0,0)',
    immediate: outAnimation ? false : true,
  })

  let imageSrc, imageAlt
  const stateShortname =
    item?.SingleProjectFields?.location[0]?.locationsFields?.location?.stateShortname

  if (item?.SingleProjectFields?.headerListingImage) {
    imageSrc = item?.SingleProjectFields?.headerListingImage?.sourceUrl
    imageAlt = item?.SingleProjectFields?.headerListingImage?.altText
  } else {
    imageSrc = item?.SingleProjectFields?.headerImage?.sourceUrl
    imageAlt = item?.SingleProjectFields?.headerImage?.altText
  }

  return (
    <LinkWrapper
      style={style}
      className={clsx('relative h-full overflow-hidden', Styles.LinkWrapper)}
      target={null} // internal
      href={item?.uri}
    >
      <div className={clsx('absolute left-0 top-0 w-full h-full bg-primary')}></div>
      <animated.div
        className={clsx('w-full h-full')}
        style={{ transform: style.scale, willChange: 'transform' }}
      >
        {tall ? (
          <Image
            // Native image must be used to get that accurate (1:2.something) aspect ratio
            layout='fill'
            src={imageSrc}
            alt={imageAlt}
            className='w-full h-auto object-cover object-center'
            lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
          />
        ) : (
          <CustomImage
            className='w-auto h-auto object-cover'
            src={imageSrc}
            alt={imageSrc}
            aspectRatio='aspect-w-1 aspect-h-1'
          />
        )}
      </animated.div>

      {/* Grid Item Info (index & Name) */}
      <div
        className={clsx(
          'absolute z-0 left-0 top-0 flex flex-col items-center justify-center w-full h-full',
          'md:hover:opacity-100 md:opacity-0', // desktop view
          'opacity-100', // mobile view
          'transition-opacity duration-300 ease-in-out',
          'px-10 text-center md:px-20'
        )}
      >
        <SubHeading
          text={stateShortname || 'none'}
          // text={index < 10 ? `0${index}` : index}
          // text='CA'
          // text={'none'}
          className='z-20 mb-35 text-secondary'
          classNameDash={Styles.Dash}
        />
        <Heading
          semantics='h3'
          text={item?.title}
          className='z-10 text-secondary text-base-B -tracking-5.5 uppercase md:text-3xl-E'
        />
        {/* Background Tint */}
        <div className='absolute z-0 w-full h-full bg-primary opacity-60 md:opacity-90'></div>
      </div>

      <animated.div
        style={{ transform: style.transform }}
        className={clsx('absolute left-0 top-0 w-full h-full bg-background')}
      />
      <animated.div
        style={{ transform: styles.transform }}
        className={clsx('absolute left-0 top-0 w-full h-full bg-background')}
      />
    </LinkWrapper>
  )
}

export default GridItem
