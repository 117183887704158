import clsx from 'clsx'
import Loader from '../../../public/icons/loader.svg'
import Copy from '../../../public/icons/copy-icon.svg'
import Plus from '../../../public/icons/plus-icon.svg'
import Plus2 from '../../../public/icons/plus2-icon.svg'
import Minus from '../../../public/icons/minus-icon.svg'
import Email from '../../../public/icons/email-icon.svg'
import Arrow from '../../../public/icons/arrow-right.svg'
import Twitter from '../../../public/icons/twitter-icon.svg'
import MenuClose from '../../../public/icons/menu-close.svg'
import MenuExpand from '../../../public/icons/menu-expand.svg'
import Facebook from '../../../public/icons/facebook-icon.svg'
import Instagram from '../../../public/icons/instagram-icon.svg'
import Link from '../../../public/icons/link-icon.svg'
import Leaders from '../../../public/icons/leaders-icon.svg'
import Tiles from '../../../public/icons/tiles-icon.svg'
import List from '../../../public/icons/list-icon.svg'

const Icon = ({
  name = 'arrow',
  className = '',
  iconClassName = '',
  clickHandler = () => {},
  border = true,
}) => {
  let Icon

  const baseIconClassName = 'fill-current object-contain'

  const iconSettings = {
    arrow: {
      component: Arrow,
      className: 'w-full',
    },
    copy: {
      component: Copy,
      className: 'w-12',
    },
    email: {
      component: Email,
      className: 'w-12',
    },
    menuClose: {
      component: MenuClose,
    },
    menuExpand: {
      component: MenuExpand,
    },
    facebook: {
      component: Facebook,
    },
    instagram: {
      component: Instagram,
    },
    twitter: {
      component: Twitter,
    },
    loader: {
      component: Loader,
      className: "animate-ping",
    },
    plus: {
      component: Plus,
      className: 'w-12 md:w-20',
    },
    plus2: {
      component: Plus2,
      className: 'w-12, md:w-20',
    },
    minus: {
      component: Minus,
      className: 'w-12, md:w-20',
    },
    link: {
      component: Link,
      className: 'w-12, md:w-20',
    },
    leaders: {
      component: Leaders,
    },
    list: {
      component: List,
    },
    tiles: {
      component: Tiles,
    }
  }

  const IconComponent = iconSettings[name].component

  return (
    <span
      onClick={clickHandler}
      className={clsx(
        'w-28 h-28',
        'pointer flex items-center justify-center',
        // 'hover:text-secondary hover:bg-primary',
        'h-contrast',
        border && 'border border-current rounded-full',
        className
      )}
    >
      <IconComponent className={clsx(
        baseIconClassName,
        iconSettings[name]?.className,
        iconClassName
      )} />
    </span>
  )
}

export default Icon
