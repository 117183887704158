import clsx from 'clsx'
import { useState, useEffect, useRef } from 'react'
import { Heading, Paragraph } from '../index'
import Styles from './Accordian.module.scss'

const Accordian = ({ className, items, firstItemOpen = true }) => {
  if (!items || items?.length === 0) return null

  return (
    <ul className={clsx(className)}>
      {items?.map((item, index) => (
        <AccordianItem
          key={index}
          title={item.title}
          description={item.description}
          isOpenDefault={index == 0} // only open 1st item by default
        />
      ))}
    </ul>
  )
}

const AccordianItem = ({ title, description, isOpenDefault }) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault)

  const handleButtonOnClick = () => setIsOpen(!isOpen)
  const ref = useRef()

  useEffect(() => {
    ref && (ref.current.style.maxHeight = `${isOpen ? ref?.current?.scrollHeight : 0}px`)
  }, [isOpen])

  return (
    <li
      key={title}
      className={clsx(
        Styles.item,
        'border-b first:border-t border-image overflow-hidden',
        'flex flex-col cursor-pointer',
        'py-30 md:py-40',
        isOpen && Styles.isOpen
      )}
      onClick={handleButtonOnClick}>
      <div className={clsx('flex items-center')}>
        <div
          className={clsx(
            'relative',
            'mr-20 md:mr-35',
            'w-20 h-20 md:w-46 md:h-46',
            'pointer flex flex-shrink-0 items-center justify-center'
          )}>
          <div
            className={clsx(
              Styles.plusMinusIcon,
              'absolute z-0',
              'w-full h-full',
              'focus-visible:outline-black focus:outline-none',
              isOpen && 'bg-accent',
              'h-contrast',
              'border border-accent rounded-full'
            )}
          />
        </div>
        <Heading
          text={title}
          semantics='h3'
          className={clsx('text-2xl-F -tracking-5.5 uppercase md:text-3xl-E')}
        />
      </div>
      <div
        ref={ref}
        className={clsx(
          Styles.paragraph,
          isOpen && 'visible opacity-100',
          !isOpen && 'invisible opacity-0'
        )}>
        <Paragraph
          text={description}
          overrideClassName={clsx(
            'paragraph text-base-A -tracking-3 md:text-base',
            'pt-20 md:pt-35',
            'max-w-440',
            'ml-40 md:ml-80'
          )}
        />
      </div>
    </li>
  )
}

export default Accordian
