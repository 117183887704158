import clsx from 'clsx'
import { CopyGroup, CustomImage, LinkWrapper } from '../index'
import { isObjectEmpty } from '../../utils'
import Styles from './SliderCard.module.scss'

const SliderCard = ({
  cta,
  externalUrl = '', // for magazines as cta obj would create a UI button
  image,
  index, // shows number on the cards
  heading,
  children,
  subHeading,
  subHeadingclassName,
  variationDash,
  description,
  className = '',
  overrideClassName,
  // customization options
  overrideCopyFromOutside = false, //default // can override default copy's layout by wrapping a custom children
  imageAspectRatio = '',
  hoverBG = false,
  drag = false,
}) => (
  <LinkWrapper
    href={
      externalUrl
        ? externalUrl // for cards without UI buttons
        : cta?.type === 'external' // for cards with UI buttons
        ? cta?.externalUrl
        : cta?.url?.[0]?.uri || cta?.url
    }
    target={cta?.type === 'external' || externalUrl ? '_blank' : null}
    key={heading}
    className={clsx(
      hoverBG && cta && Styles.slider_card,
      !hoverBG && !cta && Styles.slider_card_drag,
      overrideClassName ? overrideClassName : clsx(className)
    )}>
    <div className='relative w-full h-auto'>
      <CustomImage
        src={image?.sourceUrl}
        alt={image?.altText}
        dataPreload={true}
        aspectRatio={imageAspectRatio}
        className='w-full h-auto object-cover'
        sizes='(max-width: 768px) 100vw, 33vw'
      />

      {drag && (
        <div
          className={clsx(
            Styles.slider_drag,
            'absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2',
            'flex items-center justify-center w-100 h-100 text-center text-secondary text-sm-A bg-white bg-opacity-90 rounded-full pointer-events-none'
          )}>
          CLICK
          <br />
          AND
          <br />
          DRAG
        </div>
      )}
    </div>
    {overrideCopyFromOutside ? null : (
      <CopyGroup
        cta={isObjectEmpty(cta) ? null : { ...cta, type: 'linkWrapped' }}
        ctaBotton={cta ? true : false}
        index={index}
        heading={heading}
        subHeading={subHeading}
        variationDash={variationDash}
        subHeadingclassName={subHeadingclassName}
        description={description}
        className='pt-40'
        paragraphClassName='min-h-140 md:min-h-120 max-w-300'
      />
    )}
    {children}
  </LinkWrapper>
)

export default SliderCard
