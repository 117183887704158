/**
 * Add Site Configuration Params here
 */
export const SITENAME = 'mcalvain'
export const PROJECT_PARENT_CATEGORY_ID = 99 // PROJECTS parent category
export const WEBSITE_PARENT_CATEGORY_ID = 96 // NEWS parent category
export const GLOBAL_SITENAME = 'bigd'

/**
 * Careers
 * BIRDDOGH API filter
 */
export const CAREERS_FILTER = ['Park City', 'Jackson', 'Bozeman', 'Idaho Falls']

// Slug Names of Menus created on the frontend
export const MENU_SLUGS = {
  header: {
    primary: 'mcalvain-primary-nav',
    secondary: 'mcalvain-secondary-nav',
  },
  footer: {
    leftCol: 'mcalvain-footer-left-column',
    centralCol: 'mcalvain-footer-central-column',
    rightCol: 'mcalvain-footer-right-column',
    bottom: 'mcalvain-footer-bottom',
  },
  socialNetworks: 'mcalvain-social-networks',
}

export const STATIC_PAGE_WP_IDS = {
  home: 4141,
  about: 4132,
  contact: 4138,
  careers: 4133,
  careersDetail: 4134,
  news: 4136, // Community
  warranty: 4150,
  imprint: 4144, // Privacy Policy
  projects: 4146,
  media: 4143,
  magazines: 4142,
  termsOfServices: 4149,
  dataProtection: 4139,
  tradePartners: 4242,
  team: 4347,
}

export const SLUGS = {
  news: 'news',
  careers: 'careers',
}

// Used by News & Community overview pages
export const CATEGORY_NEWS_SLUGS = {
  news: 'news-mcalvain',
  community: 'community-mcalvain',
}

// Form Configuration
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
export const FILE_SIZE_LIMIT_MB = 8 // MegaBytes

// News Page Configuration
export const PAGINATION_SIZE = 7
export const RECOMMENDATION_POSTS_COUNT = 4
export const MAX_PAGES_MOBILE = 4

export const IMG_LAZY_LOAD_THRESHOLD = '200%'
