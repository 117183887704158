import clsx from 'clsx'

const PlayButton = ({ text = 'Play Video' }) => {
  return (
    <button
      aria-label='Play Video'
      className={clsx(
        'absolute',
        'w-100 h-100 bg-primary rounded-full md:w-175 md:h-175',
        'flex items-center justify-center',
        'focus-visible:outline-black focus:outline-none'
      )}>
      <span className='text-secondary text-base -tracking-1.5 uppercase md:text-2xl-B'>{text}</span>
    </button>
  )
}

export default PlayButton
