import clsx from 'clsx'
import {
  Heading,
  Paragraph,
  SubHeading,
  SocialIcons,
  BackgroundTintLayer,
  // SectionContainer,
} from '../index'
import placeholderImage from '../../../public/images/team-placeholder.jpg'
import Image from 'next/image'
import Styles from './TeamMemberModal.module.scss'
import MenuClose from '../../../public/icons/menu-close.svg'
import { useEffect, useState } from 'react'
import { getTeamMemberFullName } from '../../utils/team'

// BIO Description Modal
const TeamMemberModal = ({
  isModalActive,
  teamMember,
  closeModal,
}) => {
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    if (!isModalActive) {
      setHidden(true)
    } else {
      const timer = setTimeout(() => {
        setHidden(false)
      }, 300)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isModalActive])

  const image = teamMember?.secondaryImage ? teamMember?.secondaryImage : teamMember?.image
  const fullName = getTeamMemberFullName(teamMember)
  const jobTitle = teamMember?.jobTitle
  const bio = teamMember?.description
  const socialLinks = teamMember?.socialLinks
  const hasBio = !!bio?.length

  return (
    <div
      className={clsx(
        'fixed z-40 left-0 top-0 w-screen h-full bg-secondary md:bg-transparent',
        isModalActive && 'p-16 md:p-0 md:items-center md:justify-center md:flex ',
        !isModalActive && 'hidden'
      )}>
      {/* Modal Background Tint */}
      <BackgroundTintLayer
        className='hidden md:block'
        onClick={closeModal}
        active={isModalActive}
        opacity='opacity-60'
      />

      {/* Modal */}
      <div
        className={clsx(
          Styles.modal,
          `transition duration-300`,
          hidden ? 'opacity-0' : 'opacity-100',
          'bg-background',
          hasBio ? '' : 'flex md:items-center md:justify-center'
        )}
        onClick={event => event.stopPropagation()}
        role='dialog'>
        {/* Close button */}
        <button
          onClick={closeModal}
          aria-label='Close'
          className={clsx(
            Styles.button,
            'absolute z-50 right-30 top-30 md:right-20 md:top-20',
            'flex items-center justify-center',
            'rounded-full focus-visible:outline-black focus:outline-none cursor-pointer',
            'w-32 h-32 md:w-38 md:h-38',
            'text-primary',
            'h-contrast'
          )}>
          <MenuClose className='w-14 h-14 fill-current md:w-14 md:h-14' />
        </button>

        <div className={clsx(
          'z-40 flex w-full h-auto bg-background',
          'md:w-2/6'
        )}>
          <figure
            className={clsx('w-full h-auto aspect-w-1 aspect-h-1')}
          >
            <Image
              src={image?.sourceUrl || placeholderImage}
              alt={image?.altText}
              layout='fill'
              sizes='(max-width: 768px) 100vw, 25vw'
              placeholder={placeholderImage}
              className='object-cover object-center'
            />
          </figure>
        </div>

        <div
          className={clsx(
            'flex flex-1 flex-col',
            'ml-12 md:ml-74 md:mr-74 md:w-4/6',
            hasBio ? 'pt-20 md:pt-50 md:place-self-start' : "pt-20 md:pt-0 md:self-center"
          )}>
            <SubHeading text={jobTitle} className='pb-20' />

            <Heading
                semantics='h2'
                text={fullName}
                className='pb-20 text-3xl-B -tracking-5.5 uppercase md:pb-30 md:text-5xl md:-tracking-4'
            />
            <div className='pb-20 md:hidden'>
                <SocialIcons links={socialLinks} />
            </div>

            {hasBio &&
                <Paragraph
                    text={bio}
                    overrideClassName='pb-100 md:pb-80 text-base-A -tracking-3 text-primary-dark'
                />
            }

            <div className='absolute z-50 bottom-20 right-20 hidden md:block'>
                <SocialIcons links={socialLinks} />
            </div>
        </div>

        {/* Gradient layer at bottom of modal in the mobile-view */}
        <div className={Styles.modalGradient}></div>
      </div>
    </div>
  )
}

export default TeamMemberModal
