import clsx from 'clsx'
import { getTeamMemberFullName } from '../../utils/team'

const TeamListItem = ({ item, onClick }) => {
  const text = getTeamMemberFullName(item)

  return (
    <li className="text-base pb-16">
      <a
        className="cursor-pointer hover:underline"
        onClick={onClick}
      >
        {text}
      </a>
    </li>
  )
}

const TeamList = ({ items, onClickItem }) => {
  const makeHandleClickItem = (item) => (
    () => onClickItem(item)
  )

  return (
    <div className="flex">
      <ul className={clsx(
        'w-full',
        'content-start',
        'columns-2',    // 2 columns on mobile
        'md:columns-3', // 3 on tablet
        'lg:columns-4', // 4 on desktop
        'mx-16',        // Add left/right margin to account for full-width mobile layout
        'md:mx-0'       // No left/right margin on tablet/desktop, since a margin is already applied
      )}>
        {items.map((item) => (
          <TeamListItem
            item={item}
            onClick={makeHandleClickItem(item)}
            key={getTeamMemberFullName(item)}
          />
        ))}
      </ul>
    </div>
  )
}

export default TeamList;
