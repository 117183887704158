import clsx from 'clsx'
import { useRouter } from 'next/router'

import { Paragraph } from '../index'

/**
 * Note - Every page needs to be individually configured to be in draft-mode
 * TODO: Make individual page configuration to be global-ish in future
 */
const PreviewBar = () => {
  const router = useRouter()

  const handleButtonOnClick = () => {
    console.log('Exiting Preview Mode')
    fetch(`/api/exit-preview`).then(res => {
      if (res.status === 200) {
        router.push(res.url)
      }
    })
  }

  return (
    <div
      className={clsx(
        '-mt-40 md:-mt-50',
        'py-10',
        'text-secondary bg-primary',
        'flex items-center justify-center',
        'fixed left-0 right-0'
      )}
    >
      <Paragraph text='This is a preview of the draft page.' overrideClassName='text-sm-A ml-20' />
      <button
        text='Exit Preview Mode'
        onClick={handleButtonOnClick}
        className='ml-10 px-5 text-primary bg-secondary border rounded-full focus:outline-none'
      >
        Close
      </button>
    </div>
  )
}

export default PreviewBar
