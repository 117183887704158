import { useEffect, useState, useRef } from 'react'
import { Input, Button, Paragraph } from '../../index'
import { EMAIL_REGEX } from '../../../utils/siteConfig'
import { generateCF7UnitTagFromEndpointUrl, CF7_UNIT_TAG_FIELD_NAME } from '../../../utils/wpcf7'
import { Client, FileUpload, OneDriveLargeFileUploadTask } from "@microsoft/microsoft-graph-client"
import UploadFormErrorMessages from './UploadFormErrorMessages';

/**
 * Valid Form Criterias
 * 1. All values must be non-empty values &
 * 2. Email must match a regex
 */
const UploadForm = ({ className = '', text = '' }) => {
  const defaultFormFields = {
    name: '',
    email: '',
    school: '',
    date: '',
    file: '',
  }

  // Only list Required fields below for validation check
  const defaultValidFields = {
    name: false,
    email: false,
    school: false,
    date: false,
    file: false,
  }

  const refFile = useRef()

  const [formFields, setFormFields] = useState(defaultFormFields)
  const [validFields, setValidFields] = useState(defaultValidFields)
  const [submitAttempt, setFormSubmitAttempt] = useState(false)
  const [isFormValid, setFormValid] = useState(false)
  const [submitSuccessMessage, setFormSubmitSuccessMessage] = useState('')
  const [submitErrorMessage, setFormSubmitErrorMessage] = useState('')
  const [submitSpinner, setFormSubmitSpinner] = useState(false)
  const [file, setFile] = useState(undefined)
  const [uploadProgress, setUploadProgress] = useState(0)

  const handleFieldOnChange = event => {
    const { name } = event?.target
    const { value } = event?.target
    
    switch (name) {
      case 'name':
      case 'school':
      case 'date':
      case 'file':
        handleInputOnChange(name, value, true)
        break

      case 'email':
        handleEmailInputOnChange(name, value, true)
        break

      default:
        break
    }
  }

  const handleFileOnChange = event => {
    if (event.target.files.length) {
      handleFieldOnChange(event)
      setFile(event.target.files[0])
    }
  }

  // #1 Save Non-empty values on FormState
  // #2 Update their validity on ValidFields State
  const handleInputOnChange = (name, value, isRequired) => {
    const newFormState = { ...formFields, [name]: value }
    setFormFields(newFormState)

    // Only update validity state of Required field based on input length
    if (isRequired) {
      const isValidField = !!value?.length
      const newValidFields = { ...validFields, [name]: isValidField }
      setValidFields(newValidFields)
      checkFormValidity(newValidFields)
    }
  }

  const handleEmailInputOnChange = (name, value, isRequired) => {
    const newFormState = { ...formFields, [name]: value }
    setFormFields(newFormState)

    // Only update validity state of Required field based on
    // 1. Input length &
    // 2. Formatting
    if (isRequired) {
      const isValidLength = !!value?.length
      const isValidFormat = value?.match(EMAIL_REGEX) !== null
      const isValidEmail = isValidLength && isValidFormat

      const newValidFields = { ...validFields, [name]: isValidEmail }
      setValidFields(newValidFields)
      checkFormValidity(newValidFields)
    }
  }

  // Check for entire form's validity
  const checkFormValidity = newValidFields => {
    let isFormValid = true // optimistic checks
    for (const [key, value] of Object.entries(newValidFields)) {
      isFormValid = isFormValid && !!value
    }

    setFormValid(isFormValid)
  }

  const clearForm = () => {
    setFormFields(defaultFormFields);
    setValidFields(defaultValidFields);
    setFormSubmitAttempt(false);
  };

  const handleSubmit = async event => {
    event.preventDefault()

    setFormSubmitAttempt(true)
    setFormSubmitSuccessMessage('')
    setFormSubmitErrorMessage('')

    if (isFormValid) {
      setFormSubmitSpinner(true)

      var headers = new Headers()
      headers.append("Content-Type", "application/json")

      fetch(process.env.NEXT_PUBLIC_GRAPHQL_URL, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          query: "{ mcalvainGlobalSettings }",
          variables: {}
        }),
        redirect: 'follow'
      })
      .then(response => response.json())
      .then(result => JSON.parse(result.data.mcalvainGlobalSettings).onedrive_access_token)
      .then(async token => {
        const uploaded = await uploadFile(token)
        if (uploaded) {
          const cf7Endpoint = process.env.NEXT_PUBLIC_CF7_INTERNSHIP_ENDPOINT;

          const formdata = new FormData()
          formdata.append(CF7_UNIT_TAG_FIELD_NAME, generateCF7UnitTagFromEndpointUrl(cf7Endpoint));
          formdata.append('your-name', formFields.name)
          formdata.append('email', formFields.email)
          formdata.append('school', formFields.school)
          formdata.append('date', formFields.date)
          formdata.append('file', uploaded._responseBody.webUrl)
          
          const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
          }

          fetch(cf7Endpoint, requestOptions)
            .then(response => response.json())
            .then(result => {
              setFormSubmitSpinner(false)

              if (result.status == 'mail_sent') {
                setFormSubmitSuccessMessage(result.message)
                clearForm();
              } else {
                setFormSubmitErrorMessage(result.message)
              }
            })
            .catch(error => {
              console.log('Submission Error:', error)
              setFormSubmitSpinner(false)
              setFormSubmitErrorMessage('Internal server error, try later.')
            })
          
        } else {
          setFormSubmitSpinner(false)
          setFormSubmitErrorMessage('There was an error uploading the file, please contact us.')
          refFile.current.value = null
        }
      })
      .catch(error => {
        setFormSubmitSpinner(false)
        setFormSubmitErrorMessage('There was an error uploading the file, please contact us.')
        refFile.current.value = null
      })
    }
  }

  const uploadFile = async (token) => {
    try {
      const client = Client.init({
        defaultVersion: "v1.0",
        // debugLogging: true,
        authProvider: (done) => {
          done("error throw by the authentication handler", token)
        },
      })

      const blob = await new Blob([new Uint8Array(await file.arrayBuffer())], {type: file.type })
      const fileObject = new FileUpload(blob, file.name, file.size)
      const options = {
        path: "/Videos",
        fileName: Date.now() + '-' + file.name,
        conflictBehavior: "rename",
        rangeSize: 4 * 1024 * 1024,
        uploadEventHandlers: { progress: uploadProgressHandler },
      }
      const task = await OneDriveLargeFileUploadTask.createTaskWithFileObject(client, fileObject, options)      
      const uploadResult = await task.upload()
      setUploadProgress(100)
      return uploadResult
    } catch (error) {
      console.error("Upload error:", error);
      return false
    }
  }

  const uploadProgressHandler = (range) => {
    setUploadProgress(Math.round(range.minValue * 100 / range.maxValue))
  }

  return (
    <form
      name='uploadForm'
      onSubmit={handleSubmit}
      // className={`${className}`}
      className={`${className} grid grid-cols-1 md:grid-cols-2 md:gap-x-50 gap-y-20 md:gap-y-20 md:pb-50 relative z-10`}
    >
      <Input
        label='Name'
        name='name'
        id='name'
        type='text'
        value={formFields?.name}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.name && submitAttempt) ? 'true' : undefined}
        className={'md:col-span-1'}
        placeholder='John Doe'
      />

      <Input
        label='Email'
        name='email'
        id='email'
        type='email'
        value={formFields?.email}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.email && submitAttempt) ? 'true' : undefined}
        className={'md:col-span-1'}
        placeholder='example@site.com'
      />

      <Input
        label='School Name'
        name='school'
        id='school'
        type='text'
        value={formFields?.school}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.school && submitAttempt) ? 'true' : undefined}
        placeholder='School Name'
      />

      <Input
        label='Expected graduation date'
        name='date'
        id='date'
        type='text'
        value={formFields?.date}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.date && submitAttempt) ? 'true' : undefined}
        placeholder='MM/YYYY'
      />

      <div className='mt-15 md:row-start-4 md:mt-20'>
        <Button
          text={
            <>
              Upload video
              <input
                ref={refFile}
                type='file'
                name='file'
                style={{ position: 'absolute', top: 0, left: 0, fontSize: '100px', opacity: 0, cursor: 'pointer' }} 
                onChange={handleFileOnChange}
              />
            </>
          }
          invalid={!!(!validFields?.file && submitAttempt) ? 'true' : undefined}
          icon='plus2'          
          full
          generic
          disabled={submitSpinner}
          className='justify-between'          
        />
      </div>

      <div className='mt-15 md:row-start-4 md:mt-20'>
        <Paragraph
          text={text}
          overrideClassName={'paragraph'}
        />
      </div>

      <div className='mt-15 md:row-start-5 md:mt-20'>
        {/* {!submitSpinner ? (
          <Button text='Send Message' name='send-message'/>
        ) : null} */}

        {/* Form Submit Loader */}
        {/* {submitSpinner ? <Button text='Send Message' icon='loader' /> : null} */}

        <Button        
          text={submitSpinner ? <span className={'whitespace-nowrap'}>Submitting {uploadProgress}%</span> : 'Submit'}
          icon={submitSpinner ? 'loader' : 'arrow'}
          name='send'
          disabled={submitSpinner} // disable click & hover effects while n/w request is being made
          full
          className='justify-between'
        />
      </div>

      <UploadFormErrorMessages
        submitAttempt={submitAttempt}
        isFormValid={isFormValid}
        submitSuccessMessage={submitSuccessMessage}
        submitErrorMessage={submitErrorMessage}
      />

    </form>
  )
}

export default UploadForm
