import clsx from 'clsx'
import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import { animateSetUpData, randomchar, SplitText, EASE } from '../../utils'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger, SplitText)
}

import { Container, Heading, Paragraph, Link } from '../../components'

import ArrowRight from '../../../public/icons/arrow-long-right.svg'

import s from './GridFacts.module.scss'

const GridFacts = ({ className = '', title, items, button }) => {
  const sectionRef = useRef()
  const tl = useRef()
  const q = gsap.utils.selector(sectionRef)

  const NUMBER_RANDOM = '0123456789'
  const CHARACTERS_RANDOM = '~svbt'
  const NUMBER_OF_REPETITIONS_CHAR = 10
  const DELAY_BETWEEN_CHANGE_CHARS = [0.005, 0.02, 0.01]

  useEffect(() => {
    const items = q(`.${s.itemInner}`)
    const title = q(`.${s.title}`) // test

    // Split Text
    const splittext = new SplitText(q(`.${s.title}`), {
      type: 'chars',
      charsClass: 'char',
    })

    animateSetUpData(splittext.chars)

    tl.current = gsap
      .timeline({
        defaults: { ease: EASE.expoInOut, overwrite: 'auto' },
        scrollTrigger: {
          trigger: sectionRef.current,
          start: '0% 85%',
          end: '100% 0',
        },
      })
      .addLabel('init')
      .fromTo(
        [q(`.${s.header}`), q(`.${s.listWrapper}`)],
        {
          y: '15rem',
        },
        {
          y: '0rem',
          duration: 1,
        },
        'init'
      )
      .fromTo(
        items,
        {
          y: '15rem',
        },
        {
          y: '0rem',
          duration: 1,

          stagger: {
            each: 0.1,
            onStart: function () {
              const chars = Array.from(this.targets()[0].children[0].children)
              randomchar(
                chars,
                NUMBER_RANDOM,
                CHARACTERS_RANDOM,
                NUMBER_OF_REPETITIONS_CHAR,
                DELAY_BETWEEN_CHANGE_CHARS
              )
            },
          },
          delay: 0.5,
        },
        'init'
      )
      .fromTo(
        items,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.01,
          stagger: 0.1,
          delay: 0.5,
        },
        'init'
      )
      .set([q(`.${s.header}`), q(`.${s.listWrapper}`)], {
        clearProps: true,
      })
      .set(items, {
        clearProps: true,
      })

    return function () {
      tl?.current?.kill()
      gsap.set([q(`.${s.header}`), q(`.${s.listWrapper}`)], { clearProps: true })
      gsap.set(items, { clearProps: true })
    }
  }, [])

  let url = button?.externalUrl
  if (button?.type == 'internal') {
    url = button?.url?.[0]?.uri || button?.url
  }

  return (
    <section ref={sectionRef} className={clsx(s.gridFacts, className)}>
      <a id='quick-facts' style={{ position: 'absolute', marginTop: '-12rem' }}></a>
      <Container className={s.container}>
        <div className={s.gridWrapper}>
          <div className={s.header}>
            <Heading
              text={title}
              semantics='h2'
              className={(clsx(s.heading), 'md:text-sm-D uppercase mb-40')}
            />
            {url && (
              <Link href={url} prefetch={false}>
                <a
                  className={`md:text-sm-D uppercase mb-40 flex items-center cursor-pointer`}
                  target={button?.target}
                  rel={button?.target == '_blank' ? 'noopener noreferrer' : ''}>
                  <span className='-tracking-2 mr-20 text-sm-A'>{button.label}</span>
                  <span className='w-20 fill-current'>
                    <ArrowRight />
                  </span>
                </a>
              </Link>
            )}
          </div>

          <div className={s.listWrapper}>
            <div className={s.header_line}></div>
            <ul className={s.list}>
              {items?.map((item, index) => {
                return (
                  <li className={s.item} key={index}>
                    <div className={s.itemInner}>
                      <Heading
                        text={item.title}
                        semantics='h3'
                        className={clsx(s.title, 'mb-20 text-6xl md:mb-15 md:text-6xl-D')}
                      />
                      <Paragraph className={clsx(s.subHeading)} text={item.text} />
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default GridFacts
