import clsx from 'clsx'
import { isObjectEmpty } from '../../utils'

import {
  SliderCard,
  CopyGroup,
} from '../index'

const ManagementTeam = ({ items, onClickItem }) => {
  const makeHandleClickItem = (item) => (
    () => onClickItem(item)
  )

  return (
    <div className={clsx(
      'relative flex flex-wrap col-span-9',
      'flex-wrap md:px-30 md:pt-16'
    )}>
      <div className={clsx('flex flex-wrap col-span-9')}>
        {Array.isArray(items) ? items?.map((i, index) => {
          const hasModalDetail = !isObjectEmpty(i?.cta) && i?.cta?.type === 'modal'

          // TODO: Remove conditional once we eliminate the 'headline' field:
          const jobTitle = i?.headline || i?.jobTitle

          return (
            <SliderCard
              key={index}
              image={i?.image}
              className={clsx(
                'sliderCardImage px-16 w-full min-w-full',
                'md:px-30 md:w-1/3 md:min-w-auto',
                'relative mb-20 py-30 md:mb-90'
              )}
              overrideCopyFromOutside={true}
              customImageAspectRatio='aspect-h-6 aspect-w-5 md:aspect-h-10 md:aspect-w-9'
              drag={false}
            >
              <CopyGroup
                heading={i?.title}
                subHeading={i?.headline}
                description={hasModalDetail ? i.shortBio : i?.description}
                className={'pt-40 md:pl-40'}
                paragraphClassName={'pr-30 md:min-h-140'}
                cta={
                  hasModalDetail
                    ? { ...i?.cta, clickHandler: makeHandleClickItem(i) } // only add clickhandler to type modals
                  : {}
                }
              />
            </SliderCard>
          )
        })
        : null}
      </div>
    </div>
  );
}

export default ManagementTeam
