import clsx from 'clsx'
import { isLinkEmpty } from '../../utils'

import {
  Cta,
  Container,
  Paragraph,
  SubHeading,
  CustomImage,
  GridContainer,
  SectionContainer,
} from '../index'

import Styles from './MediaImageContact.module.scss'

const MediaImageContact = ({ cta, image, subHeading, title, description }) => {
  const compoundDescription = `${title}<br><br>${description}`

  return (
    <SectionContainer className='py-70 bg-background md:mb-0 md:py-150'>
      <Container>
        <GridContainer>
          {/* A - Left Image  */}
          <div
            className={clsx('relative col-span-4 -ml-16 -mr-16 overflow-hidden md:-ml-50 md:mr-0')}>
            <CustomImage
              className='w-full h-full bg-image object-cover object-center'
              src={image?.sourceUrl || ''}
              alt={image?.altText}
              width={710}
              height={710}
              sizes='(max-width: 768px) 100vw, 50vw'
              layout='responsive'
              aspectRatio='aspect-w-1 aspect-h-1 md:aspect-w-1 md:aspect-h-1'
            />
          </div>

          {/* B - Right - Copy */}
          <div className='col-span-4 pt-70 md:col-start-5 md:pt-50'>
            <SubHeading text={subHeading} className='mb-40 md:mb-70' />

            <Paragraph
              text={compoundDescription}
              overrideClassName={clsx('mb-40 text-3xl-A -tracking-3 md:mb-50 md:mr-100 md:text-4xl', Styles.paragraph)}
              className={Styles.paragraph}              
            />

            {!isLinkEmpty(cta) && <Cta cta={cta} />}
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default MediaImageContact
