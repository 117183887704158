import Head from 'next/head'
export const SITE_ADDRESS = process.env.NEXT_PUBLIC_SITE_ADDRESS

const URL = require('url')

const Seo = ({ config, globalData }) => {
  const seoConfig = globalData?.seoConfig
  const seoDefaults = globalData?.globalSettings?.seo

  // Fallback/Default values
  const fallbackTitle = seoDefaults?.seo_title
  const fallbackDescription = seoDefaults?.meta_description
  const fallbackImageFb = seoDefaults?.social__image_facebook?.url
  const fallbackImageTw = seoDefaults?.social__image_twitter?.url

  // Basic Information
  const pageTitle = config?.title?.replace('%SITE_NAME%', seoDefaults?.seo_title)
  const siteTitle = config?.opengraphSiteName || fallbackTitle
  const displayTitle = pageTitle ? pageTitle : siteTitle

  // OpenGraph
  const opengraphTitle = config?.opengraphTitle?.replace('%SITE_NAME%', seoDefaults?.seo_title) // Fallback auto resolved by YOAST
  const description = config?.metaDesc || fallbackDescription
  const opengraphDescription = config?.opengraphDescription
  const opengraphSiteName = seoDefaults?.seo_title
  let url = config?.opengraphUrl

  const opengraphPublisher = config?.opengraphPublisher
  const opengraphModifiedTime = config?.opengraphModifiedTime

  const imageOg = config?.opengraphImage?.sourceUrl || fallbackImageFb
  const ogImageWidth = config?.opengraphImage?.mediaDetails?.width
  const ogImageHeight = config?.opengraphImage?.mediaDetails?.height

  const twitterTitle = config?.twitterTitle || opengraphTitle
  const twitterDescription = config?.twitterDescription || opengraphDescription
  const imageTw = config?.twitterImage?.sourceUrl || fallbackImageTw
  const twitterCardType = seoConfig?.social?.twitter?.cardType

  const author = config?.opengraphAuthor
  const publishedDate = config?.opengraphPublishedTime

  const noFollowStatus = config?.metaRobotsNofollow || ''
  const noIndexStatus = config?.metaRobotsNoindex || ''

  // fix url for homepage
  // slug for homepages must start with '/home'
  let parsedUrl
  if (url) {
    parsedUrl = URL.parse(url)
    const path = parsedUrl.path

    if (path.lastIndexOf('/home') !== -1) {
      url = SITE_ADDRESS
    } else {
      url = `${SITE_ADDRESS}${path}`
    }
  }

  return (
    <Head>
      {/* OPEN GRAPH PROTOCOL TAG REFERENCES - https://ogp.me */}
      {/* HTML Meta Tags*/}
      <title>{displayTitle}</title>
      <meta name='description' content={description} />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />

      {/* Basic Metadata Tags*/}
      <meta property='og:title' content={opengraphTitle} />
      <meta property='og:type' content='website' />
      <meta property='og:image' content={imageOg} />
      {ogImageWidth && <meta property='og:image:width' content={ogImageWidth} />}
      {ogImageHeight && <meta property='og:image:height' content={ogImageHeight} />}
      <meta property='og:url' content={url} />

      {/* Supporting Meta Tags */}
      <meta property='og:description' content={opengraphDescription} />
      <meta property='og:site_name' content={opengraphSiteName} />

      {author && <meta property='article:author' content={author} />}
      {publishedDate && <meta property='article:published_time' content={publishedDate} />}

      {opengraphPublisher && <meta property='article:publisher' content={opengraphPublisher} />}

      {opengraphModifiedTime && (
        <meta property='article:modified_time' content={opengraphModifiedTime} />
      )}

      {/* Twitter Meta Tags */}
      <meta property='twitter:title' content={twitterTitle} />
      <meta property='twitter:description' content={twitterDescription} />
      <meta property='twitter:image' content={imageTw} />
      <meta property='twitter:card' content={twitterCardType} />
      <meta property='twitter:url' content={url} />

      {/* Tags for Bots & Crawlers */}
      {/* https://developers.google.com/search/docs/advanced/robots/robots_meta_tag */}
      <meta name='robots' content={`${noFollowStatus}, ${noIndexStatus}`} />
      <meta name='googlebot' content={`${noFollowStatus}, ${noIndexStatus}`} />
      <meta name='AdsBot-Google' content={noIndexStatus} />
      <meta name='googlebot-news' content={noIndexStatus} />

      {/* PWA */}
      <meta name='application-name' content='Big-D - Mc Alvain' />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='default' />
      <meta name='apple-mobile-web-app-title' content='Big-D - Mc Alvain' />
      <meta name='format-detection' content='telephone=no' />
      <meta name='mobile-web-app-capable' content='yes' />
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='msapplication-tap-highlight' content='no' />
      <meta name='theme-color' content='#222c32' />
      <link rel='apple-touch-icon' href='/images/icon-192.png' />
      <link rel='apple-touch-icon' sizes='192x192' href='/images/icon-192.png' />
      <link rel='apple-touch-icon' sizes='384x384' href='/images/icon-384.png' />
      <link rel='apple-touch-icon' sizes='512x512' href='/images/icon-512.png' />
      <link rel='apple-touch-icon' sizes='180x180' href='/images/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/images/icon-32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/images/icon-16.png' />
      <link rel='manifest' href='/manifest.json' />
      <link rel='mask-icon' href='/images/icon-192.png' />
      <link rel='shortcut icon' href='/images/icon-192.png' />
      <link rel='mask-icon' href='/images/safari-pinned-tab.svg' color='#222c32' />
    </Head>
  )
}

export default Seo
