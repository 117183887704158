import clsx from 'clsx'
import { useEffect, useState } from 'react'

import {
  Heading,
  Paragraph,
  Container,
  Pagination,
  SubHeading,
  ArrowButton,
  GridContainer,
  SectionContainer,
  ProjectGridsWithTransition,
  Link,
} from '../index'
import { useWindowDimensions } from '../../hooks'
import usePaginationIndex from '../../hooks/usePaginationIndex'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MediaGridImages = ({ heading, subHeading, description, projects, category }) => {
  const { width } = useWindowDimensions() // hook to get dynamic height/width

  const [pageSize, setPageSize] = useState(width <= 768 ? 6 : 7) // Current Page Index
  const [isViewportMobile, setViewPortMobile] = useState(width <= 768) // Current Page Index

  const { resortPI, residentialPI, setResortIndex, setResidentialIndex } = usePaginationIndex() // GlobalStore
  const [page, setPage] = useState(category === 'resorts' ? resortPI : residentialPI) // Current Page Index

  // sync local state with GlobalStore
  useEffect(
    () => (category === 'resorts' ? setResortIndex(page) : setResidentialIndex(page)),
    [page]
  )

  const [sliceStartIndex, setSliceStartIndex] = useState(1) // Offset in the main array
  const [sliceEndIndex, setSliceEndIndex] = useState(1) // Offset in the main array
  const [visibleProjects, setVisibleProjects] = useState([]) // active projects
  const [outAnimation, setOutAnimation] = useState(false) // active projects

  useEffect(() => {
    const sliceStartIndex = (page - 1) * pageSize // pagination start index
    const sliceEndIndex = sliceStartIndex + pageSize // pagination end index

    setSliceStartIndex(sliceStartIndex)
    setSliceEndIndex(sliceEndIndex)
    // setPageSize(width <= 768 ? 6 : 7);
    // setViewPortMobile(width <= 768);

    setVisibleProjects(projects?.filter(i => i !== null).slice(sliceStartIndex, sliceEndIndex))
    setTimeout(() => setOutAnimation(false), 500)
  }, [page, outAnimation])

  const hLengthMax = useLengthOfLongestWord(heading)

  // MAIN RENDER
  return (
    <SectionContainer className='mb-80 mt-150 md:mb-160' id='projects-grid'>
      <Container>
        {/* Heading */}
        <GridContainer className='mb-40 md:mb-120'>
          <div className='col-span-4 row-start-1 md:col-span-9 md:col-start-0.5 md:row-start-auto md:pb-0'>
            <Link href='/projects'>
              <a>
                <SubHeading
                  text={subHeading}
                  className='mb-40 hover:text-accent duration-100 ease-in md:mb-35'
                  subHeadingclassName='md:text-sm-E'
                  onScrollAnimation={true}
                  variationDash={'arrow'}
                />
              </a>
            </Link>

            <Heading
              semantics='h2'
              text={heading}
              className={clsx(
                'break-words text-4xl-D -tracking-6 uppercase md:-ml-10 md:text-left md:text-8xl md:-tracking-7',
                hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
              )}
              indent='15'
              onScrollAnimation={true}
            />
          </div>
        </GridContainer>

        <DesktopView
          description={description}
          projects={projects}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          visibleProjects={visibleProjects}
          sliceStartIndex={sliceStartIndex}
          sliceEndIndex={sliceEndIndex}
          isViewportMobile={isViewportMobile}
          outAnimation={outAnimation}
          setOutAnimation={setOutAnimation}
        />

        <MobileView
          description={description}
          projects={projects}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          visibleProjects={visibleProjects}
          sliceStartIndex={sliceStartIndex}
          sliceEndIndex={sliceEndIndex}
          isViewportMobile={isViewportMobile}
          setOutAnimation={setOutAnimation}
        />
      </Container>
    </SectionContainer>
  )
}

export default MediaGridImages

// Slider uses two separate layout for Desktop & Mobile views
const DesktopView = ({
  description,
  projects,
  page,
  pageSize,
  setPage,
  visibleProjects,
  sliceStartIndex,
  sliceEndIndex,
  isViewportMobile,
  outAnimation,
  setOutAnimation,
}) => (
  <GridContainer className='hidden md:block'>
    <div className='col-span-full'>
      <div className='grid gap-24 grid-flow-col grid-cols-4 grid-rows-3'>
        <Paragraph text={description} className='md:col-span-1 md:ml-100 md:mr-150' />
        <div className='col-start-0 flex row-span-1 row-start-5 items-end md:row-start-2'>
          <Pagination
            totalItems={projects?.length}
            currentPage={page}
            pageSize={pageSize}
            setCurrentPage={setPage}
            sliceStartIndex={sliceStartIndex}
            sliceEndIndex={sliceEndIndex}
          />
        </div>

        {/* All Slider Images are rendered inside this */}
        <ProjectGridsWithTransition
          visibleProjects={visibleProjects}
          sliceStartIndex={sliceStartIndex}
          isViewportMobile={isViewportMobile}
          outAnimation={outAnimation}
        />
        {/* <TransitionArray items={visibleProjects} /> */}

        <GridArrowButtons
          className={clsx(
            'col-end-5 row-start-3 w-full h-full',
            'flex items-center justify-center'
          )}
          page={page}
          projects={projects}
          pageSize={pageSize}
          setPage={setPage}
          outAnimation={outAnimation}
          setOutAnimation={setOutAnimation}
        />
      </div>
    </div>
  </GridContainer>
)

const MobileView = ({
  description,
  projects,
  page,
  pageSize,
  setPage,
  visibleProjects,
  sliceStartIndex,
  sliceEndIndex,
  isViewportMobile,
  setOutAnimation,
}) => (
  <GridContainer className='block md:hidden'>
    <div className='col-span-full'>
      <Paragraph text={description} className='mb-60' />

      <div className='grid gap-16 grid-flow-row grid-cols-2 grid-rows-3'>
        {/* All Slider Images are rendered inside this */}
        <ProjectGridsWithTransition
          visibleProjects={visibleProjects}
          sliceStartIndex={sliceStartIndex}
          isViewportMobile={isViewportMobile}
        />
      </div>

      <Pagination
        enablePrevNext={true}
        className={clsx('flex items-center justify-between mt-12')}
        totalItems={projects?.length}
        currentPage={page}
        pageSize={pageSize}
        setCurrentPage={setPage}
        sliceStartIndex={sliceStartIndex}
        sliceEndIndex={sliceEndIndex}
      />
    </div>
  </GridContainer>
)

const GridArrowButtons = ({
  className,
  page,
  projects,
  pageSize,
  setPage,
  outAnimation,
  setOutAnimation,
}) => {
  const isLastPage = page === Math.ceil(projects?.length / pageSize)
  const disabled = projects?.length <= pageSize // no need to show or render the next arrow button

  return !disabled ? (
    <div className={className}>
      <ArrowButton
        hidden={page === 1}
        buttonClickHandler={() => {
          setTimeout(() => setPage(page - 1), 500)
          setOutAnimation(true)
        }}
        arrowDirection='left'
        className='mr-10 w-60 h-60 md:mr-20 md:w-120 md:h-120'
        ariaLabel='Previous Items'
        outAnimation={outAnimation}
      />

      <ArrowButton
        hidden={isLastPage}
        buttonClickHandler={() => {
          setOutAnimation(true)
          setTimeout(() => setPage(page + 1), 500)
        }}
        arrowDirection='right'
        className='w-60 h-60 md:w-120 md:h-120'
        ariaLabel='Next Items'
      />
    </div>
  ) : null
}
