import clsx from 'clsx'
import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import { Heading, Paragraph, Container, SubHeading, SectionContainer } from '../index'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'
import Styles from './threeByThreeGrid.module.scss'

const ThreeByThreeGrid = ({
  heading,
  subHeading,
  benefitsArray,
  backgroundColorSection = true,
}) => {
  const GridRef = useRef()
  const q = gsap.utils.selector(GridRef)
  const tl = useRef()
  const items = benefitsArray?.length

  useEffect(() => {
    gsap.set(q('.paragraph'), {
      yPercent: 100,
    })

    tl.current = gsap
      .timeline({
        defaults: { duration: 1, ease: 'bigd-ease-out', overwrite: 'auto' },
        paused: true,
      })
      .to(q('.paragraph'), {
        yPercent: 0,
        stagger: 0.15,
      })

    ScrollTrigger.create({
      trigger: GridRef?.current,
      start: '0 70%',

      onEnter: () => {
        tl?.current?.play()
      },
    })
  }, [])

  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <SectionContainer
      className={clsx(
        'py-0 md:py-100',
        !backgroundColorSection && 'bg-none',
        backgroundColorSection && 'bg-background'
      )}>
      <Container>
        {(heading || subHeading) && (
          <div className='mb-10 md:mb-130 md:ml-80'>
            {subHeading && (
              <SubHeading text={subHeading} className='mb-40 md:mb-35' onScrollAnimation={true} />
            )}
            {heading && (
              <Heading
                semantics='h2'
                text={heading}
                className={clsx(
                  'text-4xl-C -tracking-4 uppercase md:text-8xl-A',
                  hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
                )}
                onScrollAnimation={true}
              />
            )}
          </div>
        )}

        {/* 3x3 Grid */}
        <div
          className={clsx(
            Styles.benefitGrid,
            items <= 3 && Styles.threeItems,
            items == 4 && Styles.fourItems,
            (items == 5 || items == 6) && Styles.fiveOrSixItems,
            items >= 7 && items <= 9 && Styles.sevenOrMoreItems,
            items >= 10 && Styles.tenOrMoreItems
          )}
          ref={GridRef}>
          {benefitsArray?.length
            ? benefitsArray?.map((i, index) => (
                <div
                  key={index}
                  className={clsx(
                    'col-span-4 pr-20 py-40 md:col-span-3 md:px-100 md:py-70',
                    'border-b md:border-b-0'
                  )}>
                  <SubHeading
                    text={index + 1 < 10 ? `0${index + 1}` : index + 1}
                    className='pb-20 md:pb-35'
                    onScrollAnimationTrigger={GridRef}
                  />

                  <Heading
                    semantics='h3'
                    text={i.title}
                    className='mb-40 text-2xl-F -tracking-5.5 uppercase md:text-3xl'
                  />

                  <div className='overflow-hidden'>
                    <Paragraph
                      text={i?.description}
                      overrideClassName='paragraph text-base-A -tracking-3 md:text-sm-E text-primary-dark'
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
      </Container>
    </SectionContainer>
  )
}

export default ThreeByThreeGrid
