import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import clsx from 'clsx'

import {
  SectionContainer,
  Container,
  GridContainer,
  SubHeading,
  Heading,
  ImageHeroSlider,
} from '../index'
import useIntroEnded from '../../hooks/useIntroEnded'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const HeroFullSizeSlider = ({ heroHeading, subHeading, sliderImages }) => {
  const headingRef = useRef()
  const imagesSliderRef = useRef()
  const subHeadingRef = useRef()
  const tl = useRef()

  const { introEnded } = useIntroEnded()

  useEffect(() => {
    tl.current = gsap
      .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
      .set(imagesSliderRef?.current, { opacity: 0, y: 10 })
  }, [])

  useEffect(() => {
    if (introEnded) {
      tl.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .call(() => headingRef?.current?.animateIn(), null, 'init+=.2')
        .call(() => subHeadingRef?.current?.animateIn(), null, 'init+=.85')
        .to(imagesSliderRef?.current, { opacity: 1, y: 0 }, 'init+=.8')
    }
  }, [introEnded])

  const hLengthMax = useLengthOfLongestWord(heroHeading)

  return (
    <SectionContainer className='hero-pt mb-50 md:mb-160'>
      <Container>
        <div className='flex flex-col items-center text-center'>
          <Heading
            semantics='h1'
            text={heroHeading}
            className={clsx(
              'mb-40 -tracking-4 uppercase md:mb-70',
              'text-5xl md:text-10xl',
              hLengthMax > 10 ? 'h1-scale-down-A' : hLengthMax > 8 && 'h1-scale-down-B'
            )}
            buildUpAnimation={true}
            ref={headingRef}
          />
        </div>
        {/* <GridContainer>
          <div className='col-span-4 mb-70 overflow-hidden md:col-span-3 md:col-start-3 md:mb-100'>
            <SubHeading
              text={subHeading}
              dash={false}
              uppercase={false}
              className='justify-center'
              subHeadingclassName='text-primary text-center'
              ref={subHeadingRef}
              buildUpAnimation={true}
            />
          </div>
        </GridContainer> */}

        {/* Full Sized Slider  */}

        <GridContainer>
          <div
            className='col-span-4 mx-16 md:col-span-7 md:col-start-1 md:-mx-30'
            ref={imagesSliderRef}>
            <ImageHeroSlider
              sliderImages={sliderImages}
              className='w-full'
              imageAspectRatio='aspect-h-10 aspect-w-16 md:aspect-h-9 md:h-full'
              controllerHidden={true}
              gutterPct={0}
              gutter={0}
              fromScale={0.9}
              controllerMobBottomCenter={true}
              clipPath={false}
              indexInit={1}
            />
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default HeroFullSizeSlider
