import dynamic from 'next/dynamic'
import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import clsx from 'clsx'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const Cta = dynamic(() => import('../index').then(mod => mod.Cta))
const HeroText = dynamic(() => import('../index').then(mod => mod.HeroText))
const Heading = dynamic(() => import('../index').then(mod => mod.Heading))
const Paragraph = dynamic(() => import('../index').then(mod => mod.Paragraph))
const Container = dynamic(() => import('../index').then(mod => mod.Container))
const SubHeading = dynamic(() => import('../index').then(mod => mod.SubHeading))
const CustomImage = dynamic(() => import('../index').then(mod => mod.CustomImage))
const MainContainer = dynamic(() => import('../index').then(mod => mod.MainContainer))
const GridContainer = dynamic(() => import('../index').then(mod => mod.GridContainer))

const LegalsCommonLayout = ({ pageData = {} }) => {
  const header = pageData?.signaturePageWarrantyFields?.header
  const content = pageData?.signaturePageWarrantyFields?.content
  const sidebar = pageData?.signaturePageWarrantyFields?.sidebar
  const contentItems = content?.content

  const figureRef = useRef()
  const tl = useRef()

  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: figureRef?.current,
              start: '0 100%',
              end: '100% 0',
              scrub: true,
              invalidateOnRefresh: true,
            },
          })
          .addLabel('init')
          .fromTo(
            figureRef?.current,
            {
              y: -100,
            },
            {
              y: 100,
            },
            'init'
          )

        return function () {
          tl?.current?.kill()
          gsap.set(figureRef?.current, { clearProps: true })
        }
      },
    })
  }, [])

  return (
    <div>
      <MainContainer>
        {/* HEADING */}
        <Container className='hero-pt'>
          <GridContainer className='mb-60 border-b border-image md:mb-120'>
            <div className='col-span-full md:col-start-1'>
              <HeroText
                text={header?.title}
                className='md:text-10xl -ml-16 -mr-16 mb-80 text-center text-5xl -tracking-5.5 uppercase md:mb-150 md:ml-0 md:mr-0 md:text-left md:-tracking-4.5'
                indent='15'
              />
            </div>
          </GridContainer>

          {/* Warranty Article */}
          <GridContainer>
            {/* A - Left Image  */}
            <div
              ref={figureRef}
              className='col-span-4 row-start-2 mb-60 pr-15 md:col-span-2 md:row-start-auto md:pr-0'>
              {sidebar?.image && (
                <CustomImage
                  src={sidebar?.image?.sourceUrl}
                  alt={sidebar?.image?.altText}
                  width={327}
                  height={492}
                  caption={sidebar?.imageCaption}
                  aspectRatio='aspect-w-4 aspect-h-6'
                  imageClassName='bg-image'
                  captionClassName='text-xs-caption -tracking-3 md:text-sm-A'
                />
              )}
            </div>

            {/* B - Right - Copy */}
            <div className='col-span-4 mb-0 md:col-start-3 md:mb-120'>
              {/* Content */}
              {contentItems?.length &&
                contentItems?.map((item, index) => {
                  const hLengthMax = useLengthOfLongestWord(item?.title)

                  return (
                    <div key={index} className='pb-35 md:pb-60'>
                      {item?.headline && (
                        <SubHeading text={item?.headline} className='mb-20 md:mb-80' />
                      )}

                      {item?.title && (
                        <Heading
                          semantics='h2'
                          text={item?.title}
                          className={clsx(
                            'mb-40 text-2xl-F -tracking-5.5 uppercase md:mb-50 md:text-4xl-B',
                            hLengthMax > 10
                              ? 'h2-scale-down-A'
                              : hLengthMax > 8 && 'h2-scale-down-B'
                          )}
                        />
                      )}

                      {item?.description && (
                        <Paragraph className='pb-40 md:-mr-50 md:pb-60' text={item?.description} />
                      )}

                      {item?.showCta && <Cta cta={item?.link} className='mb-40 md:mb-0' />}
                    </div>
                  )
                })}
            </div>
          </GridContainer>
        </Container>
      </MainContainer>
    </div>
  )
}

export default LegalsCommonLayout
