import clsx from 'clsx'
import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import { Container, GridContainer, Heading, Paragraph, CustomImage } from '../index'

import { EASE } from '../../utils'

import s from './ProcessImageWithText.module.scss'

const processImageWithText = ({ title, text, className = '', image }) => {
  const sectionRef = useRef()
  const q = gsap.utils.selector(sectionRef)
  const tl = useRef()
  const tlT = useRef()

  useEffect(() => {
    setTimeout(() => {
      const items = [q(`.${s.col1}`)]

      tl.current = gsap
        .timeline({
          defaults: { ease: EASE.expoInOut, overwrite: 'auto' },
          scrollTrigger: {
            trigger: q(`.${s.col1}`),
            start: '0% 90%',
            end: '100% 0',
          },
        })
        .addLabel('init')
        .fromTo(items, { y: '15rem' }, { y: '0rem', duration: 1, stagger: 0.15 }, 'init')
        .fromTo(items, { opacity: 0 }, { opacity: 1, duration: 0.333, stagger: 0.15 }, 'init')
        .set(items, {
          clearProps: true,
        })

      const itemsT = [q(`.${s.col2}`)]

      tlT.current = gsap
        .timeline({
          defaults: { ease: EASE.expoInOut, overwrite: 'auto' },
          scrollTrigger: {
            trigger: q(`.${s.col2}`),
            start: '0% 90%',
            end: '100% 0',
          },
        })
        .addLabel('init')
        .fromTo(itemsT, { y: '15rem' }, { y: '0rem', duration: 1, stagger: 0.15 }, 'init')
        .fromTo(itemsT, { opacity: 0 }, { opacity: 1, duration: 0.333, stagger: 0.15 }, 'init')
        .set(itemsT, {
          clearProps: true,
        })

      return function () {
        tl?.current?.kill()
        gsap.set(items, { clearProps: true })
      }
    }, 100)
  }, [])
  return (
    <section ref={sectionRef} className={clsx(s.processImageWithText, className)}>
      <Container className={clsx(s.processImageWithTextContainer)}>
        <GridContainer className='grid gap-0 grid-cols-1 mb-90 md:gap-20 md:grid-cols-9'>
          <div className={clsx(s.col1, 'col-span-6 col-start-1.5 mb-30 md:mb-50')}>
            <div className={s.video}>
              {/* <video preload={'none'} autoPlay={true} playsInline muted>
              <source src={videoUrl} type='video/mp4' />
            </video> */}
              <CustomImage
                src={image?.sourceUrl}
                alt={image?.altText}
                width={327}
                height={492}
                aspectRatio='aspect-w-1 aspect-h-1'
                imageClassName='bg-image'
              />
            </div>
          </div>
          <div className={clsx(s.col2, 'col-span-6 col-start-1.5 grid-cols-2 md:grid')}>
            <div className={s.col}>
              <Heading text={title} semantics='h2' className={s.title} />
            </div>
            <div className={s.col}>
              <Paragraph text={text} className={s.text} semantics='div' />
            </div>
          </div>
        </GridContainer>
      </Container>
    </section>
  )
}

export default processImageWithText
