import clsx from 'clsx'
import formatDate from '../../utils/formatDate'
import Paragraph from '../paragraph/Paragraph'
import { SLUGS, CATEGORY_NEWS_SLUGS } from '../../utils/siteConfig'
import { Heading, Container, SubHeading, SectionContainer, NewsRecommendationCard } from '../index'

const NewsRecommendation = ({ news = [], newsCategory, globalData }) => {
  if (!news) return null

  const newsRecommendation = globalData?.globalSettings?.news_recommendation
  const communitySubheading = newsRecommendation?.community_subheading
  const communityHeading = newsRecommendation?.community_heading

  const otherSubheading = newsRecommendation?.other_subheading
  const otherHeading = newsRecommendation?.other_heading  

  const isCommunity = newsCategory === CATEGORY_NEWS_SLUGS.community

  const staticCopies = {
    subHeading: isCommunity ? communitySubheading : otherSubheading,
    heading: isCommunity ? communityHeading : otherHeading,
  }

  return (
    <SectionContainer className={clsx('bg-background md:pb-180 md:pt-150', 'py-80')}>
      <Container>
        <div className='pb-60 border-b border-image md:pb-130 md:pl-80'>
          <SubHeading text={staticCopies?.subHeading} className='mb-40 uppercase md:mb-35' />

          <Heading
            semantics='h2'
            text={staticCopies?.heading}
            className='text-4xl-D -tracking-7 uppercase md:text-8xl-A'
            indent='13'
          />
        </div>

        {news ? (
          news?.map((news, index) => (
            <NewsRecommendationCard
              key={index}
              subHeading={isCommunity ? ' ' : formatDate(news?.date)}
              post={news}
              href={`/${SLUGS.news}/${news.slug}`}
            />
          ))
        ) : (
          <Paragraph
            text='News Section is currently empty. Please check later.'
            className='py-20'
          />
        )}
      </Container>
    </SectionContainer>
  )
}

export default NewsRecommendation
