import clsx from 'clsx'
import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import SplitText from '../../utils/SplitText/SplitText'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText, ScrollTrigger)
}

import Styles from './ContentText.module.scss'
import { SectionContainer, Dash, Paragraph, GridContainer, Container } from '../index'

const ContentText = ({ title, text }) => {
  const titleRef = useRef()
  const q = gsap.utils.selector(titleRef)
  const tl = useRef()

  useEffect(() => {
    const paragraphTitle = q('.text-3xl-A > p')
    if (paragraphTitle.length != 0) {
      // Split Text
      const splitText = new SplitText(paragraphTitle, {
        type: 'lines',
        linesClass: 'overflow-y-hidden pr-4',
      })

      new SplitText(paragraphTitle, {
        type: 'lines',
        linesClass: 'overflow-y-hidden',
      })

      gsap.set(splitText.lines, {
        yPercent: 100,
        overwrite: true,
      })

      ScrollTrigger.create({
        trigger: titleRef.current,
        start: 'top 80%',

        onEnter: () => {
          tl.current = gsap.timeline().to(splitText.lines, {
            yPercent: 0,
            duration: 1,
            stagger: 0.15,
            ease: 'bigd-ease-out',
            delay: 0.25,
            overwrite: true,
          })
        },
      })
    }
  }, [])

  return (
    <SectionContainer className='mb-80 md:mb-150'>
      <Container>
        <GridContainer>
          <Dash className='hidden place-self-start md:block' />

          <div
            ref={titleRef}
            className='flex flex-col col-span-4 md:col-span-3 md:col-start-3 md:-mr-80'
          >
            {title && (
              <Paragraph
                overrideClassName='md:text-4xl-C -tracking-3 text-3xl-A mb-40 md:mb-50'
                text={title}
              />
            )}

            <Paragraph text={text} className={clsx(Styles.textArea)} />
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default ContentText
