import clsx from 'clsx'
import { isObjectEmpty } from '../../utils'

import {
  SliderCard,
  CopyGroup,
  CustomImage,
} from '../index'

import TeamGalleryCard from './TeamGalleryCard'

const TeamGallery = ({ items, onClickItem }) => {
  const makeHandleClickItem = (item) => (
    () => onClickItem(item)
  )

  return (
    <div className={clsx(
      'relative col-span-9',
      'flex-wrap'
    )}>
      <div className={clsx('flex flex-wrap col-span-9 -ml-8')}>
        {Array.isArray(items) ? items?.map((item, index) => {
          return (
            <TeamGalleryCard
              key={index}
              item={item}
              onClick={makeHandleClickItem(item)}
            />
          )
        })
        : null}
      </div>
    </div>
  );
}

export default TeamGallery
