import clsx from 'clsx'
import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import SplitText from '../../utils/SplitText/SplitText'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText, ScrollTrigger)
}
import { useEventListener } from '../../hooks'

import Styles from './Heading.module.scss'

const Heading = forwardRef(
  (
    {
      text = '',
      className = '',
      semantics = 'h6', // default
      indent = false,
      buildUpAnimation = false,
      onScrollAnimation = false,
      onScrollAnimationTrigger = false,
      onScrollAnimationDelay = 0,
    },
    ref
  ) => {
    if (!text) return null
    let component
    const $heading = useRef()
    const headingClassName = clsx(className, Styles.heading)
    const [textSplit, setTextSplit] = useState(false)
    const tl = useRef()

    useEventListener('keydown', ({ key }) => {
      if (key === '1') {
        animateSetUp(textSplit)
        setTimeout(() => animateIn(), 250)
      }
      if (key === '2') {
        animateSetUp2(textSplit)
        setTimeout(() => animateIn2(), 250)
      }
      if (key === '3') {
        animateSetUp3(textSplit)
        setTimeout(() => animateIn3(), 250)
      }
      if (key === '4') {
        animateSetUp4(textSplit)
        setTimeout(() => animateIn4(), 250)
      }
      if (key === '5') {
        animateSetUp5(textSplit)
        setTimeout(() => animateInLines(), 250)
      }
    })

    const animateSetUp = splitText => {
      gsap.set(splitText.words, {
        yPercent: 100,
        overwrite: true,
      })
    }

    const animateIn = () => {
      tl.current = gsap.timeline().to(textSplit.words, {
        yPercent: 0,
        duration: 1,
        stagger: 0.15,
        ease: 'bigd-ease-out',
        delay: 0.25,
        overwrite: true,
      })
    }

    const animateSetUp2 = splitText => {
      gsap.set(splitText.chars, {
        yPercent: 100,
        overwrite: true,
      })
    }

    const animateIn2 = () => {
      tl.current = gsap.timeline().to(textSplit.chars, {
        yPercent: 0,
        duration: 1,
        stagger: 0.05,
        ease: 'bigd-ease-out',
        delay: 0.25,
        overwrite: true,
      })
    }

    const animateSetUp3 = splitText => {
      gsap.set(splitText.chars, {
        yPercent: 100,
        scale: 0.9,
        overwrite: true,
      })
    }

    const animateIn3 = () => {
      tl.current = gsap.timeline().to(textSplit.chars, {
        yPercent: 0,
        scale: 1,
        duration: 1,
        stagger: {
          each: 0.025,
          from: 'center',
        },
        ease: 'bigd-ease-out',
        delay: 0.25,
        overwrite: true,
      })
    }

    const animateSetUp4 = splitText => {
      gsap.set(splitText.chars, {
        opacity: 0,
        xPercent: -10,
        scale: 0.8,
        overwrite: true,
      })
    }

    const animateIn4 = () => {
      tl.current = gsap.timeline().to(textSplit.chars, {
        opacity: 1,
        scale: 1,
        duration: 0.5,
        xPercent: 0,
        stagger: 0.025,
        ease: 'bigd-ease-out',
        delay: 0.25,
        overwrite: true,
      })
    }

    const animateSetUp5 = splitText => {
      gsap.set(splitText.lines, {
        yPercent: 100,
        overwrite: true,
      })
    }

    const animateInLines = () => {
      tl.current = gsap.timeline().to(textSplit.lines, {
        yPercent: 0,
        duration: 1,
        stagger: 0.15,
        ease: 'bigd-ease-out',
        overwrite: true,
      })
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          animateIn() {
            animateIn()
          },
          animateInLines() {
            animateInLines()
          },
        }
      },
      [textSplit]
    )

    useEffect(() => {
      if (indent || buildUpAnimation || onScrollAnimation || onScrollAnimationTrigger) {
        // Split Text
        const splitText = new SplitText($heading.current, {
          type: buildUpAnimation === 'lines' ? 'lines' : 'lines, words, chars',
          linesClass: 'overflow-y-hidden pr-4',
        })

        setTextSplit(splitText)

        // Indent
        if (indent) {
          if (indent === 'align-right') {
            // Align right
            gsap.set(splitText.lines[1], { textAlign: 'right' })
          } else {
            // Padding left
            gsap.set(splitText.lines[1], { paddingLeft: indent + 'rem' })
          }
        }

        if (buildUpAnimation || onScrollAnimation || onScrollAnimationTrigger) {
          switch (buildUpAnimation) {
            case 'lines':
              new SplitText($heading.current, {
                type: 'lines',
                linesClass: 'overflow-y-hidden',
              })
              animateSetUp5(splitText)
              break
            default:
              animateSetUp(splitText)
          }
        }

        if (onScrollAnimation || onScrollAnimationTrigger) {
          ScrollTrigger.create({
            trigger: onScrollAnimationTrigger ? onScrollAnimationTrigger.current : $heading.current,
            start: 'top 80%',
            onEnter: () => {
              switch (buildUpAnimation) {
                case 'lines':
                  tl.current = gsap.timeline().to(splitText.lines, {
                    yPercent: 0,
                    duration: 1,
                    stagger: 0.15,
                    ease: 'bigd-ease-out',
                    delay: 0.25 + onScrollAnimationDelay,
                    overwrite: true,
                  })
                  break
                default:
                  tl.current = gsap.timeline().to(splitText.words, {
                    yPercent: 0,
                    duration: 1,
                    stagger: 0.15,
                    ease: 'bigd-ease-out',
                    delay: 0.25 + onScrollAnimationDelay,
                    overwrite: true,
                  })
              }
            },
          })
        }
      }
    }, [])

    // Semantics based header-rendering
    switch (semantics) {
      case 'h1':
        component = (
          <h1
            className={headingClassName}
            dangerouslySetInnerHTML={{ __html: text }}
            ref={$heading}
          ></h1>
        )
        break

      case 'h2':
        component = (
          <h2
            className={headingClassName}
            dangerouslySetInnerHTML={{ __html: text }}
            ref={$heading}
          ></h2>
        )
        break

      case 'h3':
        component = (
          <h3
            className={headingClassName}
            dangerouslySetInnerHTML={{ __html: text }}
            ref={$heading}
          ></h3>
        )
        break

      case 'h4':
        component = (
          <h4
            className={headingClassName}
            dangerouslySetInnerHTML={{ __html: text }}
            ref={$heading}
          ></h4>
        )
        break

      case 'h5':
        component = (
          <h5
            className={headingClassName}
            dangerouslySetInnerHTML={{ __html: text }}
            ref={$heading}
          ></h5>
        )
        break

      case 'h6':
        component = (
          <h6
            className={headingClassName}
            dangerouslySetInnerHTML={{ __html: text }}
            ref={$heading}
          ></h6>
        )
        break

      default:
        component = (
          <h6
            className={headingClassName}
            dangerouslySetInnerHTML={{ __html: text }}
            ref={$heading}
          ></h6>
        )
        break
    }

    return <>{component}</>
  }
)

export default Heading
