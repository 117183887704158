import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import stringToSlug from '../../utils/slugify'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}
import {
  Paragraph,
  Container,
  SocialIcons,
  ItemsRenderer,
  GridContainer,
  FooterLogos,
} from '../index'
import clsx from 'clsx'
import Image from 'next/image'
import Styles from './Footer.module.scss'

const Footer = ({ title, subTitle, globalMenus, footerLogos, greatPlaceToWorkLogoUrl }) => {
  const footer = globalMenus?.footer

  const footerLeftColItems = footer?.leftCol?.menuItems?.nodes
  const footerCentralColItems = footer?.centralCol?.menuItems?.nodes
  const footerRightColItems = footer?.rightCol?.menuItems?.nodes
  const footerbottomItems = footer?.bottom?.menuItems?.nodes
  // const hash = require('string-hash');

  const NavRenderer = ({ className, title, items, anchorClassName }) => {
    const slugifiedTitle = title ? stringToSlug(title) : ''
    return (
      <nav className={`pt-40 md:pt-0 flex flex-col col-span-4 md:col-span-1.5 ${className}`}>
        <h3
          className={'pb-20 -tracking-3 uppercase text-sm-A md:text-lg xl:pb-35'}
          id={`footer-navigation-items-heading-${slugifiedTitle}`}>
          {title}
        </h3>

        <ItemsRenderer
          showActiveDash={false}
          isFooter={true}
          list={items}
          className='flex flex-col text-primary text-sm-B -tracking-3 md:text-sm-C md:space-y-10'
          anchorClassName='button-underline'
          ariaLabelledBy={`footer-navigation-items-heading-${slugifiedTitle}`}
        />
      </nav>
    )
  }

  const footerRef = useRef()
  const footerPRef = useRef()
  const tl = useRef()

  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: footerRef?.current,
              scrub: true,
              invalidateOnRefresh: true,
              start: '0% 100%',
              end: '100% 100%',
            },
          })
          .addLabel('init')
          .fromTo(
            footerPRef?.current,
            {
              yPercent: -50,
            },
            {
              yPercent: 0,
            },
            'init'
          )

        return function () {
          tl?.current?.kill()
          gsap.set(footerPRef?.current, { clearProps: true })
        }
      },
    })
  }, [])

  return (
    <footer
      id='footer'
      ref={footerRef}
      className={clsx(
        Styles.footer,
        'flex flex-col justify-center text-primary bg-secondary overflow-hidden'
      )}>
      <div ref={footerPRef} style={{ willChange: 'transform' }}>
        <h2 className='sr-only'>Footer</h2>
        <Container>
          {/* Top Half */}
          <GridContainer className='pb-60 pt-0 md:pb-130 md:pt-150'>
            {/* Tag Line - Fanatical Craftmansip */}
            <div className='col-span-4 row-start-1 space-y-18 md:col-span-2 md:col-start-1 md:row-start-auto'>
              <Paragraph
                text={title}
                overrideClassName='footer-title text-4xl-A -tracking-3 md:text-4xl-C'
              />
              <div className='overflow-hidden'>
                <Paragraph
                  text={subTitle}
                  overrideClassName='text-primary text-sm-C -tracking-3 xl:pt-20'
                />
              </div>
            </div>

            {/* COMPANY */}
            <NavRenderer
              className='md:col-start-4'
              title={footer?.leftCol?.acf?.title}
              items={footerLeftColItems}
            />

            {/* INFORMATION */}
            <NavRenderer
              // className='col-span-4 md:col-span-1.5'
              title={footer?.centralCol?.acf?.title}
              items={footerCentralColItems}
            />

            {/* REACH OUT */}
            <NavRenderer
              // className='col-span-4 md:col-span-1.5'
              title={footer?.rightCol?.acf?.title}
              items={footerRightColItems}
            />

            {/* DATA - Show on Mobile only */}
            <NavRenderer className='hidden' title='Data' items={footerbottomItems} />

            <div className={`pt-40 md:pt-0 flex flex-col col-span-1 md:col-span-1 md:row-start-1 md:col-start-9`}>
              <Image
                src={greatPlaceToWorkLogoUrl}
                alt={'Great Place to Work'}
                height='150%'
                width='100%'
                objectFit='contain'
              />
            </div>
          </GridContainer>
        </Container>

        <div className='px-16 md:px-30'>
          <GridContainer
            className={clsx(
              'pb-28 pt-24 md:pb-15 md:pt-20',
              'border-white/[.15] border-t',
              'flex items-center justify-center align-middle'
            )}>
            <div className='col-span-3'>
              <Paragraph
                text='The Big-D Family of Companies'
                overrideClassName='text-primary text-sm-A -tracking-3 uppercase'
              />
            </div>

            {/* <div className='col-span-1 col-start-4 justify-self-center'>
              <SocialIcons header={false} globalMenus={globalMenus} />
            </div> */}

            <div className='hidden md:block md:col-span-7 md:col-start-6.5 md:justify-self-end lg:col-span-4'>
              <h3 className='sr-only' id='footer-bottom-navigation'>
                Footer Bottom Navigation
              </h3>
              <ItemsRenderer
                isFooter={true}
                showActiveDash={false}
                list={footerbottomItems}
                className='flex text-xs-C uppercase space-x-40'
                anchorClassName='button-underline'
                ariaLabelledBy='footer-bottom-navigation'
              />
            </div>
          </GridContainer>
          <FooterLogos footerLogos={footerLogos} />
        </div>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
