import clsx from 'clsx'
import React from 'react'

import {
  Dash,
  Cta,
  Heading,
  Paragraph,
  Container,
  SubHeading,
  ImageSlider,
  GridContainer,
  SectionContainer,
} from '../index'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const SliderText = ({ sliderImages, heading, subHeading, description, cta }) => {
  return (
    <SectionContainer className='pb-100 pt-80 bg-secondary md:pb-150 md:pt-130'>
      <Container>
        <GridContainer>
          {/* C - LEFT - Copy */}
          <CopyComponent
            heading={heading}
            subHeading={subHeading}
            description={description}
            cta={cta}
          />

          {/* C - RIGHT - IMAGE SLIDER */}
          <div className='z-0 col-span-4 -mr-16 md:col-start-5 md:-mr-50'>
            <ImageSlider
              sliderImages={sliderImages}
              className='w-4/5'
              imageClassName='overflow-hidden'
              imageAspectRatio='aspect-w-3 aspect-h-4 md:aspect-w-5 md:aspect-h-5 h-full'
              clipPath='left'
            />
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

// export default SliderText;
export default React.memo(SliderText)

const CopyComponent = ({ heading, subHeading, description, cta }) => {
  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <div className='z-10 col-span-4 md:col-span-5 md:pl-50 md:pt-40'>
      <SubHeading text={subHeading} className='pb-40 md:pb-35 md:pt-60' onScrollAnimation={true} />

      <Heading
        semantics='h2'
        text={heading}
        className={clsx(
          'pb-40 text-4xl-D -tracking-4 uppercase md:-mr-30 md:pb-70 md:text-8xl md:-tracking-7',
          hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
        )}
        onScrollAnimation={true}
      />

      <div className={clsx('flex items-start mb-10', 'pr-16 2xl:pr-240 md:pr-120 xl:pr-180')}>
        <Dash variation='short' className='hidden pt-10 text-primary-dark md:block md:mr-85' />

        <div>
          <Paragraph text={description} className='paragraph mb-40 text-primary-dark md:mb-80' />

          {cta && <Cta cta={cta} className='inline-block mb-20 md:mb-0' />}
        </div>
      </div>
    </div>
  )
}
