import React from 'react';
import clsx from 'clsx'
import PropTypes from 'prop-types';
import { Icon, Link } from '../index'

import Button from '../button/Button';

const ToggleOption = ({ active, icon, label, onClick, className }) => {
  return (
    <div>
      <button
        className={clsx(
          'inline-flex w-full justify-center items-center',
          'text-base uppercase',
          active ? 'text-white' : 'text-primary-dark',
          'focus:outline-none focus-visible:outline-none',
          'hover:text-white',
          className
        )}
        onClick={onClick}
      >
        <Icon
          border={false}
          iconClassName="w-48"
          className='mr-6'
          name={icon}
        />
        {label}
      </button>
    </div>
  )
}

const Toggle = ({ options, value, onChange }) => {
  const makeHandleClickOption = (value) => {
    return () => onChange(value);
  };

  return (
    <div className='inline-flex gap-20 md:gap-40'>
      {options.map(({icon, label, value: optionValue}) => (
        <ToggleOption
          active={optionValue === value}
          icon={icon}
          label={label}
          onClick={makeHandleClickOption(optionValue)}
          key={optionValue}
        />
      ))}
    </div>
  );
}

export default Toggle;

const toggleOptionPropType = PropTypes.shape({
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
})

Toggle.propTypes = {
  options: PropTypes.arrayOf(toggleOptionPropType).isRequired,
  onChange: PropTypes.func.isRequired,
}
