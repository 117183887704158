import clsx from 'clsx'
import { useRef, useEffect } from 'react'
import Image from 'next/image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import { IMG_LAZY_LOAD_THRESHOLD } from '../../utils/siteConfig'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

import Styles from './mediaListText.module.scss'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Accordian,
  Cta,
  Dash,
  Heading,
  CopyGroup,
  Paragraph,
  Container,
  SubHeading,
  GridContainer,
  SectionContainer,
} from '../index'

const MediaListText = ({
  className = '',
  image,
  heading,
  headingClassName,
  indent,
  subHeading,
  description,
  imagePosition = 'left',
  background = 'bg-white',
  accordianItems,
  backgroundColorSection,
}) => {
  const imageRef = useRef()
  const q = gsap.utils.selector(imageRef)
  const tl = useRef()

  useEffect(() => {
    imageRef?.current &&
      (tl.current = gsap
        .timeline({
          defaults: { duration: 1, ease: 'bigd-ease-out' },
          scrollTrigger: {
            trigger: imageRef?.current,
            scrub: true,
            invalidateOnRefresh: true,
          },
        })
        .fromTo(
          q('.figure'),
          {
            scale: 1.2,
            yPercent: 10,
          },
          {
            scale: 1.2,
            yPercent: -10,
          }
        ))
  }, [])

  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <SectionContainer className={clsx(className, background)}>
      <Container>
        <div className='md:grid md:gap-20 md:grid-flow-row-dense md:grid-cols-9'>
          <div
            className={clsx(
              ' mb-60 md:pt-0',
              imagePosition === 'right' && 'md:col-start-4.5 md:col-span-4.5',
              imagePosition === 'left' && ' md:col-start-0 col-span-6',
              Styles.headingWrapper
            )}>
            <SubHeading
              text={subHeading}
              className='mb-40 px-16 md:mb-35 md:pl-70 md:px-0'
              onScrollAnimation={true}
            />
            <Heading
              semantics='h2'
              text={heading}
              className={clsx(
                'text-4xl-D md:text-8xl-A',
                'relative z-10 px-16 -tracking-4 uppercase md:pl-70 md:px-0 md:-tracking-7',
                headingClassName,
                hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
              )}
              indent={indent}
              onScrollAnimation={true}
            />
            <Paragraph
              text={description}
              className='px-16 max-w-450 break-words md:ml-210 md:mt-80 md:px-0'
            />
          </div>
        </div>

        <GridContainer>
          <ImageComponent
            className={clsx(
              'relative col-span-4 mb-50 overflow-hidden md:mb-0',
              '-ml-16 -mr-16',
              imagePosition === 'left' && 'md:col-start-0  md:-ml-50 md:mr-0',
              imagePosition === 'right' && 'md:col-start-5 md:-mr-50 md:ml-0 row-start-1'
            )}
            image={image}
            imageRef={imageRef}
            background={background}
          />
          <Accordian
            className={clsx(
              'items-center self-stretch',
              'row-start-0 md:row-start-auto',
              'col-span-4',
              ' md:mr-30 md:mt-150',
              imagePosition === 'left'
                ? 'md:col-start-5 md:col-span-4.5 md:-ml-40'
                : 'md:col-start-0 md:col-span-5'
            )}
            items={accordianItems}
            firstItemOpen={true}
          />
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default MediaListText

const ImageComponent = ({ image, background, imageRef, imagePosition, className }) => (
  <div className={className}>
    <figure
      ref={imageRef}
      className={clsx(
        'md:aspect-square object-cover object-center overflow-hidden',
        imagePosition === 'right' && 'h-380 md:h-710',
        imagePosition === 'left' && 'h-380 md:h-820'
      )}>
      <Image
        className='figure w-full bg-image object-cover'
        src={image?.sourceUrl || ''}
        alt={image?.altText}
        width={1415}
        height={2370}
        sizes='(max-width: 768px) 100vw, 50vw'
        layout='responsive'
        lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
      />
    </figure>
  </div>
)

const CopyComponent = ({ description, Styles, cta }) => {
  const CustomListRef = useRef()

  return (
    <div className='row-start-0 flex col-span-4 items-center self-stretch md:col-span-4.5 md:col-start-4.5 md:row-start-auto md:-mr-50'>
      {description ? (
        <div className='flex items-start mb-10 pr-16 md:pl-60 md:pr-160'>
          {Array.isArray(description) ? null : (
            <Dash variation='short' className='hidden md:block' />
          )}

          <div ref={CustomListRef} className={Styles.customList}>
            {description ? (
              Array.isArray(description) ? (
                <>
                  {description?.length &&
                    description?.map((i, index) => (
                      <CopyGroup
                        className='py-35 border-b border-primary md:border-none'
                        key={index}
                        index={index + 1}
                        description={i?.description}
                        heading={i?.title}
                        paragraphClassName='md:max-w-300'
                        onScrollAnimation={true}
                      />
                    ))}
                </>
              ) : (
                <Paragraph text={description} className='mb-40 md:mb-80' />
              )
            ) : null}

            <Cta cta={cta} className='hidden md:inline-block' />
          </div>
        </div>
      ) : null}
    </div>
  )
}
