import Heading from '../heading/Heading'
import Paragraph from '../paragraph/Paragraph'
import MediaVideo from '../mediaVideo/MediaVideo'
import NewsImage from '../newsImage/NewsImage'

const NewsBuilder = ({ data, fieldPrefix = 'Post_Signaturepostnewsfields_ContentBuilder_' }) => {
  let outputBlocksArray = data?.map((element, index) => {
    const componentName = element?.fieldGroupName?.replace(fieldPrefix, '')

    switch (componentName) {
      case 'Headline':
        return (
          <Heading
            key={index}
            semantics='h2'
            text={element.text}
            className='font-helveticaNeue mb-40 text-3xl-A -tracking-3 md:mb-150 md:text-4xl-C'
          />
        )

      case 'Text':
        return <Paragraph key={index} className='mb-80 md:mb-150' text={element.text} />

      case 'Videoembed':
        return (
          <MediaVideo key={index} videoUrl={element?.videoUrl} posterImage={element?.videoPoster} />
        )

      case 'Image':
        return <NewsImage key={index} image={element?.image} />

      default:
        return null
    }
  })

  return outputBlocksArray?.length ? outputBlocksArray : null
}

export default NewsBuilder
