import clsx from 'clsx'
import { useEffect, useState, useCallback } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') gsap.registerPlugin(ScrollTrigger)

import { ArrowButton } from '../../components'
import { PAGINATION_SIZE, MAX_PAGES_MOBILE } from '../../utils/siteConfig'

import Styles from './Pagination.module.scss'

const Pagination = ({
  totalItems,
  currentPage,
  setCurrentPage,
  className,
  executeScroll = () => {},
  pageSize = PAGINATION_SIZE,
  sliceStartIndex = 0,
  sliceEndIndex = PAGINATION_SIZE,
  enablePrevNext = false,
}) => {
  const pageCount = Math.ceil(totalItems / pageSize)
  const [desktopPagination, setDesktopPagination] = useState('')
  const [mobilePagination, setMobilePagination] = useState('')

  const handleClick = useCallback(
    i => {
      setCurrentPage(i)
      executeScroll()
      setTimeout(() => ScrollTrigger.refresh(), 1000) // makes sure footer doesn't hide when using pager
    },

    [executeScroll, setCurrentPage]
  )

  const handleKeypress = useCallback(
    (e, i) => {
      if (e.key === 'Enter') {
        handleClick(i)
      }
    },
    [handleClick]
  )

  // Dynamically generate the list of pagination items based on length of blog[]
  useEffect(() => {
    const desktopPagination = []
    const mobilePagination = []

    if (pageCount > 1) {
      for (let i = 1; i <= pageCount; i++) {
        const Item = () => (
          <li key={i}>
            <button
              className={clsx(
                Styles.paginationButton,
                'mb-1.5 mr-1.5',
                'text-sm-A -tracking-3 cursor-pointer',
                'border rounded-full',
                'flex items-center justify-center',
                'hover:text-secondary hover:bg-primary border hover:border-primary',
                'focus-visible:outline-black focus:outline-none',
                currentPage == i ? 'border-primary' : 'border-transparent'
              )}
              style={{
                transition:
                  'border-color 0.3s cubic-bezier(0, 0, .2, 1), background-color 0.3s cubic-bezier(0, 0, .2, 1), color 0.3s cubic-bezier(0, 0, .2, 1)',
              }}
              onClick={() => handleClick(i)}
              onKeyPress={e => handleKeypress(e, i)}
            >
              <span>{i?.toString()?.length === 1 ? `0${i}` : i}</span>
            </button>
          </li>
        )
        desktopPagination.push(Item())
      }
    }

    if (pageCount > MAX_PAGES_MOBILE) {
      // run a loop 4 times & determine each item with a switch case
      for (let i = 1; i <= MAX_PAGES_MOBILE; i++) {
        let currentValue

        // use switch case to determine 1st, 2nd, 3rd & 4th item
        switch (i) {
          // first item
          case 1:
            // when current page is either first 2 or last 2 pages
            if (currentPage <= 2 || currentPage >= pageCount - 1) {
              currentValue = 1
            } else {
              currentValue = currentPage - 1
            }
            break

          // second Item
          case 2:
            // first page
            if (currentPage === 1) {
              currentValue = currentPage + 1 // i.e 2
            }
            // last 2 pages
            else if (currentPage >= pageCount - 1) {
              currentValue = '...'
            } else {
              currentValue = currentPage
            }
            break

          // third item
          case 3:
            if (currentPage <= pageCount - 2) {
              currentValue = '...'
            } else {
              currentValue = pageCount - 1
            }
            break

          // fourth Item
          case 4:
            currentValue = pageCount
            break
        }

        const Item = () => (
          <li key={i}>
            <button
              className={clsx(
                Styles.paginationButton,
                'mb-1.5 mr-1.5',
                'text-sm-A -tracking-3 cursor-pointer',
                'border rounded-full',
                'flex items-center justify-center',
                currentValue !== '...' &&
                  'hover:bg-primary hover:border-primary hover:text-secondary',
                'focus-visible:outline-black focus:outline-none',
                currentPage == currentValue ? 'border-primary' : 'border-transparent'
              )}
              style={{
                transition:
                  'border-color 0.3s cubic-bezier(0, 0, .2, 1), background-color 0.3s cubic-bezier(0, 0, .2, 1), color 0.3s cubic-bezier(0, 0, .2, 1)',
              }}
              onClick={() => handleClick(currentValue)}
              onKeyPress={e => handleKeypress(e, i)}
              disabled={currentValue === '...'}
            >
              <span
                className={clsx(
                  '-mb-2' // place offset to center text vertically within border
                )}
              >
                {currentValue?.toString()?.length === 1 ? `0${currentValue}` : currentValue}
              </span>
            </button>
          </li>
        )
        mobilePagination.push(Item())
      }
    }

    setDesktopPagination(desktopPagination)
    setMobilePagination(mobilePagination)
  }, [currentPage, pageCount])

  if (totalItems <= PAGINATION_SIZE) return null
  return (
    <div className={clsx(className)}>
      <ArrowButton
        className={clsx('md:hidden', !enablePrevNext && 'hidden')}
        disabled={sliceStartIndex === 0}
        arrowDirection='left'
        buttonClickHandler={() => handleClick(currentPage - 1)}
      />

      <ul className={clsx('flex md:hidden')}>
        {pageCount <= MAX_PAGES_MOBILE ? desktopPagination : mobilePagination}
      </ul>

      <ul className={clsx('hidden flex-wrap md:flex')}>{desktopPagination}</ul>

      <ArrowButton
        className={clsx('md:hidden', !enablePrevNext && 'hidden')}
        disabled={sliceEndIndex >= totalItems}
        arrowDirection='right'
        buttonClickHandler={() => handleClick(currentPage + 1)}
      />
    </div>
  )
}

export default Pagination
