import clsx from 'clsx'
import { useState, useEffect } from 'react'

const BackgroundTintLayer = ({
  onClick,
  active,
  className,
  children,
  opacity = 'opacity-40', // default 40
  backgroundColor = 'bg-secondary', // white by default
}) => {
  const [hidden, setHidden] = useState(true)
  const duration = 700

  useEffect(() => {
    if (active) {
      setHidden(false)
    } else {
      const timer = setTimeout(() => {
        setHidden(true)
      }, duration)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [active])

  return (
    <div
      onClick={onClick}
      className={clsx(
        'fixed z-40 w-screen h-screen opacity-0 filter',
        backgroundColor,
        `transition duration-${duration}`,
        !active && hidden && 'hidden',
        !hidden && active && opacity,
        className
      )}
    >
      {children}
    </div>
  )
}

export default BackgroundTintLayer
