import clsx from 'clsx'
import { Heading, Container, SectionContainer } from '../index'
import Styles from './statistics.module.scss'

const Statistics = ({ statistics }) => (
  <>
    {statistics && (
      <SectionContainer className='border-b border-primary md:border-t'>
        <Container className='grid grid-cols-4 px-0 py-0 2xl:max-w-screen-2xl md:grid-cols-8 md:max-w-none'>
          {statistics?.length
            ? statistics?.map((i, index) => (
                <div
                  key={index}
                  className={clsx(
                    Styles.statisticscGridItem,
                    'flex flex-col col-span-1 items-center justify-center px-5 py-20 min-h-120 md:px-40 md:py-110',
                    'border-r last:border-r-0 border-t border-primary md:border-t-0'
                  )}
                >
                  <Heading
                    semantics='h3' // perhaps none
                    text={i?.quantity}
                    className='text-3xl-A -tracking-3 uppercase md:mb-26 md:text-6xl'
                  />
                  <Heading
                    semantics='h4'
                    text={i?.title}
                    className='text-center text-xs-B -tracking-5.5 uppercase md:text-2xl-C'
                  />
                </div>
              ))
            : null}
        </Container>
      </SectionContainer>
    )}
  </>
)

export default Statistics
