import clsx from 'clsx'
import { useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import { useGesture } from '@use-gesture/react'
import { ResizeObserver } from '@juggle/resize-observer'
import { useWindowDimensions } from '../../hooks'

import {
  Cta,
  Heading,
  Container,
  SliderCard,
  SubHeading,
  GridContainer,
  SectionHeading,
  SectionContainer,
} from '../index'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const SectionSlider = ({
  id,
  heading,
  headingExtraIndent = false,
  subHeading,
  variationDash,
  subHeadingclassName,
  sliderCards,
  className = '',
  sectionCta,
  imageAspectRatio,
  hoverBG = false,
  sliderCardClassName = 'min-w-80vw md:min-w-390 max-w-80vw md:max-w-390', // customized to make magazines look smaller
  titleOnScrollAnimationDelay = 0,
}) => {
  const [drag, setDrag] = useState(true)

  const [styles, updateStyles] = useSpring(() => ({
    x: 0,
    config: { tension: 150 },
  }))

  const { width: windowsWidth } = useWindowDimensions()

  const [containerRef, { width, left, top }] = useMeasure({
    scroll: true,
    polyfill: ResizeObserver,
  })

  const [gridRef, { width: widthGrid }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const runSprings = ox => {
    updateStyles.start(() => {
      return {
        x: ox,
      }
    })
  }

  const bind = useGesture(
    {
      onDrag: ({ down, event, offset: [ox] }) => {
        down && event.preventDefault()
        runSprings(ox)
        if (drag) {
          setDrag(false)
        }
      },
    },
    {
      drag: {
        bounds: {
          left: -(width - widthGrid - (windowsWidth > 768 ? 80 : 25)),
          right: 0,
        },
        rubberband: true,
        filterTaps: true,
      },
    }
  )

  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <SectionContainer className={className} id={id}>
      <Container>
        <GridContainer className='mb-60 overflow-hidden md:mb-130'>
          <SectionHeading
            className={headingExtraIndent ? 'col-span-4 md:col-span-7 md:col-start-2' : ''}
            position={headingExtraIndent ? 'none' : 'left'}>
            <SubHeading
              text={subHeading}
              className='mb-40 md:mb-35'
              onScrollAnimation={true}
              onScrollAnimationDelay={titleOnScrollAnimationDelay}
            />
            <Heading
              semantics='h2'
              text={heading}
              className={clsx(
                '-mr-16 text-left text-4xl-D -tracking-4 uppercase md:mr-0 md:text-8xl-A md:-tracking-7',
                hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
              )}
              indent='15.5'
              onScrollAnimation={true}
              onScrollAnimationDelay={titleOnScrollAnimationDelay}
            />
          </SectionHeading>
        </GridContainer>

        {/* Slider Cards - 3x1 */}
        <div
          {...bind()}
          ref={gridRef}
          style={{ cursor: 'grab', touchAction: 'auto' }}
          className='relative'>
          <GridContainer className='pb-60 border-b border-image md:pb-100'>
            {/* Horizontal Scroll on mobile */}
            <animated.div
              onDragStart={e => e.preventDefault()}
              ref={containerRef}
              style={{
                ...styles,
                willChange: 'transform',
                touchAction: 'pan-y',
                width: 'min-content',
              }}
              className={clsx(
                'flex col-span-9 select-none',
                '-mr-16 md:-mr-50', // bleed on the right
                'md:-ml-50 md:pl-100' // bleed & pad on the left
              )}>
              {sliderCards?.length &&
                sliderCards?.map((i, index) => {
                  return (
                    <SliderCard
                      className={clsx('text-left', 'mr-25 md:mr-80', sliderCardClassName)}
                      key={index}
                      cta={i?.cta}
                      externalUrl={i?.externalUrl} // for magazines as cta obj would create a UI button
                      image={i?.image}
                      heading={i?.heading}
                      subHeading={i?.subHeading}
                      subHeadingclassName={subHeadingclassName}
                      variationDash={variationDash}
                      description={i?.description}
                      imageAspectRatio={imageAspectRatio}
                      // overrideCopyFromOutside={true}
                      hoverBG={hoverBG}
                      drag={drag}
                    />
                  )
                })}
            </animated.div>
          </GridContainer>
        </div>

        {/* Section CTA */}
        <Cta
          cta={sectionCta}
          className='flex justify-center pt-20 md:items-end md:justify-end md:pt-30'
        />
      </Container>
    </SectionContainer>
  )
}

export default SectionSlider
