import clsx from 'clsx'
import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import { Container, Paragraph, Heading } from '../index'
import { EASE, pad } from '../../utils'

import s from './ListSteps.module.scss'

const ListSteps = ({ className = '', title = '', items }) => {
  const sectionRef = useRef()
  const q = gsap.utils.selector(sectionRef)
  const tl = useRef()

  useEffect(() => {
    setTimeout(() => {
      const items = [q(`.${s.itemInner}`)]

      tl.current = gsap
        .timeline({
          defaults: { ease: EASE.expoInOut, overwrite: 'auto' },
          scrollTrigger: {
            trigger: sectionRef?.current,
            start: '0% 50%',
            end: '100% 0',
          },
        })
        .addLabel('init')
        .fromTo(
          items,
          {
            y: '15rem',
          },
          {
            y: '0rem',
            duration: 1,
            stagger: 0.15,
          },
          'init'
        )
        .fromTo(
          items,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.333,
            stagger: 0.15,
          },
          'init'
        )
        .set(items, {
          clearProps: true,
        })

      return function () {
        tl?.current?.kill()
        gsap.set(items, { clearProps: true })
      }
    }, 100)
  }, [])

  return (
    <section
      ref={sectionRef}
      className={clsx(s.listSteps, className, 'py-115 mx-0 md:mx-30 md:py-0')}>
      <Container className={s.container}>
        <div className={'flex items-center justify-between mb-20 md:mb-65'}>
          <Heading text={title} semantics='h2' className={'text-sm-A uppercase'} />
        </div>
        <ul
          className={
            'grid grid-cols-1 md:grid-cols-2 p-0 gap-0 md:gap-0 border-b border-primary-dark'
          }>
          {items?.map((item, index) => {
            return (
              <li className={clsx(s.item, 'md:gap-0 md:col-auto')} key={index}>
                <div className={'py-70 md:pb-30 md:pt-50 overflow-hidden'}>
                  <div className={'grid md:grid-cols-3'}>
                    <div className={'flex md:items-start'}>
                      <div className={clsx(s.number, 'text-6xl md:text-6xl-D')}>{index + 1}</div>
                    </div>
                    <Paragraph
                      text={item.description}
                      className={clsx(s.text, 'mt-5 md:col-span-1 md:mt-4')}
                      semantics='div'
                    />
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </Container>
    </section>
  )
}

export default ListSteps
