import clsx from 'clsx'
import { useTransition, animated } from '@react-spring/web'

import { GridItem } from '../../index'

const ProjectGridsWithTransition = ({
  visibleProjects,
  sliceStartIndex,
  isViewportMobile,
  outAnimation,
}) => {
  const transitions = useTransition(visibleProjects, {
    from: { transform: 'translate3d(0%,0,0)', scale: 'scale(1.2)' },
    enter: { transform: 'translate3d(100%,0,0)', scale: 'scale(1.009)' },
    config: { mass: 1, tension: 120, friction: 26 },
    trail: 100,
  })

  return transitions((style, item, transitionObj, index) => (
    <animated.div
      className={clsx(
        'w-full h-full',
        !isViewportMobile && index === 1 && 'overflow-hidden row-span-2' // 2nd tall item
      )}
    >
      <GridItem
        style={style}
        key={index}
        item={item}
        index={sliceStartIndex + ++index} // displayIndex
        tall={!isViewportMobile && index === 2} // 2nd tall item
        isViewportMobile={isViewportMobile}
        outAnimation={outAnimation}
      />
    </animated.div>
  ))
}

export default ProjectGridsWithTransition
