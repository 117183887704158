import clsx from 'clsx'

import { Heading, Paragraph, SubHeading, ArrowButton, GridContainer, Link } from '../../components'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

import Styles from './NextProject.module.scss'

const NextProject = ({ href = '', title = '', location = '' }) => {
  const hLengthMax = useLengthOfLongestWord(title)

  return (
    <GridContainer className={clsx('mb-65 pt-40 md:mb-180 md:pt-30', Styles.nextProject)}>
      <div
        className={clsx(
          'col-start-0 flex flex-col',
          hLengthMax > 10 ? 'col-span-4 md:col-span-9' : 'col-span-4 md:col-span-7 md:col-start-1'
        )}
      >
        <Link href={href}>
          <a>
            <SubHeading
              text='Next Project'
              className='justify-center mb-15 text-center text-2xl-A uppercase md:justify-start md:mb-30 md:pl-15 md:text-left'
              dash={false}
              subHeadingclassName='text-2xl-A md:text-2xl-B'
            />
            <Heading
              semantics='h2'
              text={title}
              className={clsx(
                'place-self-center mb-40 -tracking-4 uppercase md:mb-24 md:-tracking-4.5',
                'text-5xl md:text-8xl-A',
                hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 6 && 'h2-scale-down-B'
              )}
              indent='align-right'
            />
            <div className='overflow-hidden'>
              <Paragraph
                text={location}
                overrideClassName='md:place-self-end text-2xl-A -tracking-3 uppercase md:text-4xl-C place-self-center text-center md:text-right mb-40 md:mb-24'
              />
            </div>
            <div className='flex justify-center md:justify-end'>
              <ArrowButton
                arrowDirection='right'
                className={clsx('place-self-center border-2 md:place-self-end', Styles.arrowButton)}
                hoverFlip={true}
                strokeThickness={true}
              />
            </div>
          </a>
        </Link>
      </div>
    </GridContainer>
  )
}

export default NextProject
