import clsx from 'clsx'
import { useEffect, useState } from 'react'
import Styles from './MediaVideo.module.scss'

import {
  Heading,
  Container,
  PlayButton,
  SubHeading,
  CustomImage,
  GridContainer,
  SectionContainer,
} from '../index'
import { getEmbedUrlAndPlatform } from '../../utils'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MediaVideo = ({ heading, subHeading, videoUrl, posterImage }) => {
  const [isVideoPlayerEnabled, setIsVideoPlayerEnabled] = useState(false)
  const [embededVideoUrl, setEmbededVideoUrl] = useState(null)
  const [platform, setPlatform] = useState(null)

  useEffect(() => {
    const { embededUrl, platform } = getEmbedUrlAndPlatform(videoUrl)
    setEmbededVideoUrl(embededUrl)
    setPlatform(platform)
  }, [videoUrl])

  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <SectionContainer className='mb-80 md:mb-150'>
      <Container>
        {heading && subHeading && (
          <GridContainer className='items-center'>
            {/* A - Heading & Subheading  */}
            <div className='col-span-4 md:col-span-7 md:col-start-1.5'>
              <SubHeading text={subHeading} className='mb-40 md:mb-35' />

              <div className='mb-60 md:mb-130'>
                <Heading
                  semantics='h2'
                  text={heading}
                  className={clsx(
                    'text-4xl-D -tracking-4 uppercase md:text-8xl-A md:-tracking-7',
                    hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
                  )}
                  indent='18'
                />
              </div>
            </div>
          </GridContainer>
        )}

        {/* B PosterImage + Video */}
        <GridContainer className='relative'>
          <div
            className={clsx(
              'col-span-4 md:col-span-7 md:col-start-1',
              'object-fit flex -mx-16 md:mx-0',
              'bg-background'
            )}
            onClick={() => setIsVideoPlayerEnabled(true)}
          >
            {!isVideoPlayerEnabled ? (
              <div
                className={clsx(
                  Styles.play_btn,
                  'relative flex items-center justify-center w-full h-full cursor-pointer'
                )}
              >
                <CustomImage
                  src={posterImage?.sourceUrl}
                  alt={posterImage?.altText}
                  aspectRatio='aspect-w-16 aspect-h-9'
                  className='w-full h-full'
                  imageClassName='bg-image'
                />
                <PlayButton />
              </div>
            ) : (
              <div className={clsx('w-full h-full', 'aspect-w-16 aspect-h-9')}>
                { platform == 'youtube' &&
                  <iframe
                    id='videoIframe'
                    src={embededVideoUrl}
                    className='object-fit flex w-full h-full'
                    width='1920'
                    height='1080'
                    title=''
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  ></iframe>
                }

                { platform == 'vimeo' && 
                  <iframe
                    id='videoIframe'
                    src={embededVideoUrl}
                    className='object-fit flex w-full h-full'
                    width='1920'
                    height='1080'
                    frameBorder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowFullScreen
                  ></iframe>
                }

                { !platform && 
                  <video src={embededVideoUrl} controls autoplay={'autoplay'}></video>
                }
              </div>
            )}
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}
export default MediaVideo
