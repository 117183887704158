import clsx from 'clsx'
import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'

import {
  Heading,
  Container,
  Paragraph,
  SubHeading,
  ImageSlider,
  GridContainer,
  SectionContainer,
} from '../index'

import useIntroEnded from '../../hooks/useIntroEnded'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const HeroSlider = ({ heroHeading, heading, subHeading, sliderImages, description }) => {
  const headingRef = useRef()
  const heading2Ref = useRef()
  const imagesSliderRef = useRef()
  const subHeadingRef = useRef()
  const paragraphRef = useRef()
  const tl = useRef()

  const { introEnded } = useIntroEnded()

  useEffect(() => {
    tl.current = gsap
      .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
      .set(paragraphRef?.current, { opacity: 0 })
      .set(imagesSliderRef?.current, { opacity: 0 })
      .set(imagesSliderRef?.current, { yPercent: 10 })
  }, [])

  useEffect(() => {
    if (introEnded) {
      tl.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .call(() => headingRef?.current?.animateIn(), null, 'init')
        .to(imagesSliderRef?.current, { duration: 0.2, opacity: 1 }, 'init')
        .to(imagesSliderRef?.current, { yPercent: 0 }, 'init')
        .call(() => subHeadingRef?.current?.animateIn(), null, 'init+=.4')
        .call(() => heading2Ref?.current?.animateInLines(), null, 'init+=.6')
        .to(paragraphRef?.current, { duration: 0.3, opacity: 1 }, 'init+=.7')
    }
  }, [introEnded])

  const hLengthMax = useLengthOfLongestWord(heroHeading)

  return (
    <SectionContainer className='hero-pt pb-100 md:pb-0'>
      <Container>
        <GridContainer className='pb-50 md:pb-0'>
          <div className='col-span-4 md:col-span-7 md:col-start-2'>
            <Heading
              semantics='h1'
              text={heroHeading}
              className={clsx(
                'md:text-10xl text-5xl -tracking-4 uppercase',
                hLengthMax > 10 ? 'h1-scale-down-A' : hLengthMax > 8 && 'h1-scale-down-B'
              )}
              indent='50'
              buildUpAnimation={true}
              ref={headingRef}
            />
          </div>
        </GridContainer>

        <GridContainer>
          {/* B - Left Image Slide  */}
          <div className='relative z-0 col-span-4 -ml-16 -mr-16 md:-ml-50' ref={imagesSliderRef}>
            <ImageSlider
              sliderImages={sliderImages}
              imageClassName='overflow-hidden'
              imageAspectRatio='aspect-w-4 aspect-h-5 md:aspect-w-7 md:aspect-h-9'
              controllerPositionX='right'
              controllerPositionY='bottom'
              clipPath='right'
            />
          </div>

          {/* B - Right - Copy */}
          <div className='z-10 col-span-4 pt-80 md:col-start-5 md:pt-200'>
            <SubHeading
              text={subHeading}
              className='mb-40 md:mb-80'
              ref={subHeadingRef}
              buildUpAnimation={true}
            />
            <div className='pb-40 md:pb-50 md:w-550'>
              {/* Don't apply scale down here - reason: text is paragraph */}
              <Heading
                semantics='h2'
                text={heading}
                className='font-helveticaNeue text-3xl-A -tracking-3 md:text-4xl-A'
                buildUpAnimation={'lines'}
                ref={heading2Ref}
              />
            </div>
            <div ref={paragraphRef}>
              <Paragraph text={description} className='md:max-w-450' />
            </div>
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default HeroSlider
