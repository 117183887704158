// import { useRouter } from 'next/router'
// import { useEffect } from 'react'
import dynamic from 'next/dynamic'

// import { scrollToTargetAdjusted } from '../../utils'

const MainContainer = dynamic(() => import('../index').then(mod => mod.MainContainer))
const MediaListText = dynamic(() => import('../index').then(mod => mod.MediaListText))
const HeroFullSizeSlider = dynamic(() => import('../index').then(mod => mod.HeroFullSizeSlider))

const ProjectsLayout = ({ pageData }) => {
  // const router = useRouter()
  // useEffect(() => {
  //   if (process?.browser) {
  //     // detect ID in url & then scroll to
  //     let targetElementID = router?.asPath?.split('#')?.[1]
  //     scrollToTargetAdjusted(targetElementID, 60)
  //   }
  // }, [])

  const hero = pageData?.mcalvainPageProjects?.header
  const sliderImages = pageData?.sliderItems
  const preconServices = pageData?.mcalvainPageProjects?.preconServices
  const constManagement = pageData?.mcalvainPageProjects?.constManagement
  const postConstruction = pageData?.mcalvainPageProjects?.postConstruction
  const structuralConcrete = pageData?.mcalvainPageProjects?.structuralConcrete
  const excavation = pageData?.mcalvainPageProjects?.excavation

  return (
    <MainContainer>
      {/* SECTION A - HERO SLIDER (FULL SIZE) */}
      <HeroFullSizeSlider
        heroHeading={hero?.title}
        subHeading={hero?.tagline}
        sliderImages={sliderImages}
      />

      {/* SECTION B - PreCon Services  - 3x2*/}
      {/* <MediaListText
        className='py-65 md:py-150'
        background='bg-background'
        image={preconServices?.image}
        heading={preconServices?.title}
        indent={'16'}
        subHeading={preconServices?.headline}
        description={preconServices?.description}
        accordianItems={preconServices?.items}
      /> */}

      {/* SECTION C - Construction Management */}
      {/* <MediaListText
        imagePosition='right'
        background='bg-secondary'
        className='py-65 md:py-150'
        headingClassName='md:-ml-740'
        indent={'25'}
        backgroundColorSection={false}
        heading={constManagement?.title}
        subHeading={constManagement?.headline}
        image={constManagement?.image}
        description={constManagement?.description}
        accordianItems={constManagement?.items}
      /> */}

      {/* SECTION D - Post Construction */}
      {/* <MediaListText
        background='bg-background'
        className='py-65 md:py-150'
        headingClassName='md:-mr-300'
        heading={postConstruction?.title}
        subHeading={postConstruction?.headline}
        image={postConstruction?.image}
        description={postConstruction?.description}
        accordianItems={postConstruction?.items}
      /> */}

      {/* SECTION E - Structural Concrete */}
      {/* <MediaListText
        imagePosition='right'
        background='bg-secondary'
        className='py-65 md:py-150'
        headingClassName='md:-ml-740'
        backgroundColorSection={false}
        heading={structuralConcrete?.title}
        indent={'52'}
        subHeading={structuralConcrete?.headline}
        image={structuralConcrete?.image}
        description={structuralConcrete?.description}
        accordianItems={structuralConcrete?.items}
      /> */}

      {/* SECTION F - Excavation */}
      {/* <MediaListText
        background='bg-background'
        className='py-65 md:py-150'
        headingClassName='md:-mr-300'
        heading={excavation?.title}
        subHeading={excavation?.headline}
        image={excavation?.image}
        description={excavation?.description}
        accordianItems={excavation?.items}
      /> */}
    </MainContainer>
  )
}

export default ProjectsLayout
