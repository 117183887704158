export function animateSetUpData(chars) {
  chars.map((char, i) => {
    char.setAttribute('data-char', `${char.innerHTML}`)
  })
}

export function animateSetInit(chars, _CHARACTERS_RANDOM, _CHART_INIT) {
  chars.map((char, i) => {
    if (_CHART_INIT) {
      char.innerHTML = _CHART_INIT
    } else {
      char.innerHTML = _CHARACTERS_RANDOM.charAt(
        Math.floor(Math.random() * _CHARACTERS_RANDOM.length)
      )
    }
  })
}

export function randomchar(
  charArray,
  _NUMBER_RANDOM,
  _CHARACTERS_RANDOM,
  _NUMBER_OF_REPETITIONS_CHAR,
  _DELAY_BETWEEN_CHANGE_CHARS
) {
  const noChangeChar = ['.', ',', '$', '+']

  charArray.map((char, iC) => {
    let lastCharacter = char.getAttribute('data-char')
    let result = ''
    let time = 0
    const repeat = _NUMBER_OF_REPETITIONS_CHAR + iC

    for (let i = 0; i < repeat; i++) {
      time +=
        _DELAY_BETWEEN_CHANGE_CHARS[Math.floor(Math.random() * _DELAY_BETWEEN_CHANGE_CHARS.length)]

      setTimeout(function () {
        if (i === repeat - 1) {
          char.innerHTML = lastCharacter
        } else {
          if (!isNaN(lastCharacter)) {
            const _RANDOM = _NUMBER_RANDOM
            result = _RANDOM.charAt(Math.floor(Math.random() * _RANDOM.length))
          } else {
            if (noChangeChar.indexOf(lastCharacter) != -1) {
              result = lastCharacter
            } else {
              const _RANDOM = _CHARACTERS_RANDOM
              result = _RANDOM.charAt(Math.floor(Math.random() * _RANDOM.length))
            }
          }
          char.innerHTML = result
        }
      }, time * 1000 * i)
    }
  })
}
