import clsx from 'clsx'
import Styles from './HamburgerMenu.module.scss'

import MenuExpand from '../../../../public/icons/menu-expand.svg'
import MenuClose from '../../../../public/icons/menu-close.svg'

const HamburgerMenu = ({ active = false, handleOnClick = () => {}, className = '' }) => {
  return (
    <button
      className={clsx(
        'absolute z-50 right-0 flex items-center justify-center rounded-full focus:outline-none cursor-pointer',
        'w-42 h-42 md:w-58 md:h-58',
        active && Styles.active,
        'text-primary md:focus-visible:outline-white',
        !active && 'bg-none text-secondary md:focus-visible:outline-black',
        Styles.hamburger,
        className
      )}
      onClick={handleOnClick}
      aria-label='Toggle menu'>
      {active ? (
        <MenuClose className='w-20 h-20 fill-current md:w-30 md:h-30' />
      ) : (
        <MenuExpand className='w-20 h-20 fill-current md:w-30 md:h-30' />
      )}
    </button>
  )
}

export default HamburgerMenu
