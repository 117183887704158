/**
 * check empty link
 * @param {object} cta link to check
 */
export default function isLinkEmpty(cta) {
  let url = cta?.externalUrl
  if (cta?.type == 'internal') {
    url = cta?.url?.[0]?.uri || cta?.url
  }

  return url == null
}
