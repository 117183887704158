import clsx from 'clsx'
import React, { useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import s from '../mediaVideo/MediaVideo.module.scss'
import style from './MediaText.module.scss'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Cta,
  Dash,
  Heading,
  Paragraph,
  CopyGroup,
  Container,
  SubHeading,
  VideoModal,
  PlayButton,
  CustomImage,
  GridContainer,
  SectionContainer,
} from '../index'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MediaText = ({
  cta,
  image,
  video = false,
  indent = false,
  heading,
  subHeading,
  headingImage,
  description,
  imagePosition = 'left',
  aspectRatio,
  hasVideoPlayback = false,
  backgroundColorSection = true,
  className = '',
  customDescriptionWidth = false,
}) => {
  const copyPosition = imagePosition === 'left' ? 'right' : 'left'
  const imageRef = useRef()
  const q = gsap.utils.selector(imageRef)
  const tl = useRef()

  const [isVideoPlayerEnabled, setIsVideoPlayerEnabled] = useState(false)

  useEffect(() => {
    imageRef?.current && (tl.current = gsap.set(q('figure'), { scale: 1.1 }))

    imageRef?.current &&
      (tl.current = gsap
        .timeline({
          defaults: { duration: 1, ease: 'bigd-ease-out' },
          scrollTrigger: {
            trigger: imageRef?.current,
            start: '30% 100%',
          },
        })
        .to(q('figure'), { scale: 1 }))
  }, [])

  return (
    <SectionContainer
      className={clsx(
        'pt-80',
        !backgroundColorSection && 'bg-none',
        backgroundColorSection && 'bg-background',
        className ? className : 'md:pt-0'
      )}
    >
      <Container className='py-0'>
        <GridContainer className='grid-flow-row items-center md:grid-flow-col'>
          {/* A - LEFT Image  */}
          <ImageComponent
            imagePosition={imagePosition}
            image={image}
            aspectRatio={aspectRatio}
            imageRef={imageRef}
            hasVideoPlayback={hasVideoPlayback}
            setIsVideoPlayerEnabled={setIsVideoPlayerEnabled}
          />

          {/* B - RIGHT - Copy */}
          <CopyComponent
            cta={cta}
            heading={heading}
            subHeading={subHeading}
            headingImage={headingImage}
            description={description}
            copyPosition={copyPosition}
            indent={indent}            
            customDescriptionWidth={customDescriptionWidth}
          />

          <VideoModal
            id='joinUsCareersVideo'
            isModalActive={isVideoPlayerEnabled}
            onClose={() => setIsVideoPlayerEnabled(false)}
            src={video}
          />
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

// export default MediaText;
export default React.memo(MediaText)

const ImageComponent = ({
  imagePosition,
  image,
  aspectRatio,
  imageRef,
  hasVideoPlayback = false,
  setIsVideoPlayerEnabled,
}) => {
  return (
    <div
      className={clsx(
        'relative col-span-4 -ml-16 -mr-16 h-full overflow-hidden md:mr-0',
        imagePosition === 'left' && 'md:col-start-0 md:-ml-50 md:mr-0',
        imagePosition === 'right' && 'md:col-start-5 md:ml-0 md:-mr-50',
        'flex items-center justify-center',
        hasVideoPlayback && 'cursor-pointer',
        hasVideoPlayback && s.play_btn
      )}
      ref={imageRef}
      onClick={() => {
        hasVideoPlayback && setIsVideoPlayerEnabled(true)
      }}
    >
      <CustomImage
        src={image?.sourceUrl}
        alt={image?.altText}
        className='w-full h-full bg-image object-cover object-center'
        sizes='(max-width: 768px) 100vw, 50vw'
        aspectRatio={aspectRatio}
      />
      {hasVideoPlayback && <PlayButton />}
    </div>
  )
}

const CopyComponent = ({ copyPosition, cta, heading, subHeading, headingImage, description, indent, customDescriptionWidth }) => {
  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <div
      className={clsx(
        'flex col-span-4 items-center self-stretch md:col-span-4.5',
        'row-start-1 md:row-start-auto',
        copyPosition === 'left' && 'md:col-start-0.5 row-start-0 md:row-start-0',
        copyPosition === 'right' && 'md:col-start-4.5'
      )}
    >
      <div>
        { !headingImage && <>
          <SubHeading text={subHeading} className='mb-40 md:mb-35' onScrollAnimation={true} />
          <Heading
            semantics='h2'
            text={heading}
            className={clsx(
              'heading text-4xl-D -tracking-4 uppercase md:text-8xl-A md:-tracking-7',
              'mb-40 md:mb-80',
              hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
            )}
            indent={indent}
            onScrollAnimation={true}
          />
        </> }

        {description ? (
          <div className={clsx('flex items-start mb-10 pr-16', '2xl:mr-160 md:ml-50 md:mr-100  ')}>            
            <Dash variation='short' className={`hidden pt-10 text-image md:block md:mr-85 ${headingImage ? 'opacity-0' : ''}`} />

            <div>
              {Array.isArray(description) ? (
                <>
                  {description?.length &&
                    description?.map((i, index) => (
                      <CopyGroup
                        key={index}
                        index={index + 1}
                        heading={i?.title}
                        description={i?.description}
                      />
                    ))}
                </>
              ) : (
                <>
                  { headingImage && 
                    <CustomImage
                      src={headingImage?.sourceUrl}
                      alt={headingImage?.altText}
                      className='w-full h-full object-cover object-center max-w-2xl mb-40'
                      sizes='(max-width: 768px) 100vw, 50vw'
                      aspectRatio='aspect-w-1 aspect-h-1'
                    />
                  }

                  <Paragraph
                    text={description}
                    className={clsx('paragraph mb-40 text-primary-dark md:mb-80', customDescriptionWidth ? style.customDescriptionWidth : '')}
                    
                  />
                </>                  
              )}

              {cta && <Cta cta={cta} className='inline-block mb-20 md:mb-0' />}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
