import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Heading,
  Container,
  Paragraph,
  SubHeading,
  SliderCard, // created a local version here
  GridContainer,
  SectionContainer,
} from '../index'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const ThreeColList = ({
  heading,
  subHeading,
  imageCardA,
  imageCardB,
  imageCardC,
  titleCardA,
  titleCardB,
  titleCardC,
  descCardA,
  descCardB,
  descCardC,
}) => {
  const GridRef = useRef()
  const q = gsap.utils.selector(GridRef)
  const tl = useRef()

  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        q('.sliderCardImage')[1] &&
          q('.sliderCardImage')[2] &&
          (tl.current = gsap
            .timeline({
              scrollTrigger: {
                trigger: GridRef?.current,
                start: '0 90%',
                end: '50% 50%',
                scrub: true,
                invalidateOnRefresh: true,
              },
            })
            .addLabel('init')
            .fromTo(
              q('.sliderCardImage')[1],
              {
                y: 100,
              },
              {
                y: 0,
              },
              'init'
            )
            .fromTo(
              q('.sliderCardImage')[2],
              {
                y: 200,
              },
              {
                y: 0,
              },
              'init'
            ))

        return function () {
          tl?.current?.kill()
          gsap.set(q('.sliderCardImage'), { clearProps: true })
        }
      },
    })

    setTimeout(() => ScrollTrigger.refresh(), 1000)
  }, [])

  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <SectionContainer className='pb-50 md:pb-0'>
      <Container>
        {/* B-Headings */}
        <GridContainer>
          <div className='col-span-full md:ml-30'>
            <SubHeading text={subHeading} className='mb-40 md:mb-35' onScrollAnimation={true} />

            <div className='pb-60 md:pb-130'>
              <Heading
                semantics='h2'
                text={heading}
                className={clsx(
                  'text-4xl-D -tracking-4 uppercase md:text-8xl-A',
                  hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
                )}
                indent='19'
                onScrollAnimation={true}
              />
            </div>
          </div>
        </GridContainer>

        <GridContainer>
          {/* Horizontal Scroll on mobile */}
          <div
            ref={GridRef}
            className={clsx(
              'flex col-span-9',
              'scrollbar-hide overflow-x-scroll overflow-y-hidden md:overflow-visible',
              'md:flex-wrap', // wraps around items to a new row if items are more than 3
              '-mx-16 md:-mx-30' // bleed both sides on mobile
            )}>
            <CustomizedSliderCard
              image={imageCardA}
              title={titleCardA}
              description={descCardA}
              index='1'
            />
            <CustomizedSliderCard
              image={imageCardB}
              title={titleCardB}
              description={descCardB}
              index='2'
            />
            <CustomizedSliderCard
              image={imageCardC}
              title={titleCardC}
              description={descCardC}
              index='3'
            />
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default ThreeColList

// Individual Card Components (LOCAL)
const CustomizedSliderCard = ({ image, title, description, index, className }) => (
  <SliderCard
    key={title}
    image={image}
    overrideCopyFromOutside={true}
    className='sliderCardImage px-16 w-5/6 min-w-80vw pointer-events-none md:px-70 md:w-1/3 md:min-w-auto'
    imageAspectRatio='aspect-h-2 aspect-w-3 md:aspect-h-3 md:aspect-w-4'>
    <div className='pt-40 md:pt-30'>
      {/* Number & Label */}
      <div className='flex items-center pb-30 md:pb-38'>
        <div className='flex items-center justify-center mr-16 min-w-40 w-40 h-40 border border-2 border-current rounded-full md:mr-20 md:min-w-46 md:w-46 md:h-46'>
          <span className='text-primary text-base md:text-sm-A'>{`0${index}`}</span>
        </div>

        <Heading
          semantics='h3'
          text={title}
          className='text-2xl-F -tracking-5.5 uppercase md:text-3xl-A'
        />
      </div>

      <Paragraph
        text={description}
        overrideClassName='text-base-A -tracking-3 md:text-base pr-20 md:pr-30 text-primary-dark'
      />
    </div>
  </SliderCard>
)
