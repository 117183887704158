import { Button } from '../index'
import { isObjectEmpty } from '../../utils'
import stringToSlug from '../../utils/slugify'

const Cta = ({ cta = {}, className = '' }) => {
  if (isObjectEmpty(cta)) return null // check empty objects
  let url
  let name = cta?.name ? cta?.name : stringToSlug(cta?.label)

  switch (cta?.type) {
    case 'internal':
      url = cta?.url?.[0]?.uri || cta?.url
      break

    case 'external':
      url = cta?.externalUrl
      break

    case 'modal':
      url = ''
      break

    case 'linkWrapped': // Use when cta is already wrapped by a LinkWrapper component
    case 'action':
      url = null
      break

    default:
      url = cta?.externalUrl || cta?.url
  }

  return (
    <div className={className}>
      <Button
        text={cta?.label}
        target={cta?.target}
        href={url}
        buttonColor={cta?.buttonColor}
        icon={cta?.icon}
        buttonClickHandler={cta?.clickHandler}
        name={name}
        fullWidthMobile={cta?.fullWidthMobile}
      />
    </div>
  )
}

export default Cta
