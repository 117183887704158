import clsx from 'clsx'
import React, { useState, useEffect } from 'react'

import { Link, Heading, Paragraph, CustomImage, GridContainer, SectionContainer } from '../index'
import Styles from './HeroHome.module.scss'
import useIntroEnded from '../../hooks/useIntroEnded'
import { useWindowDimensions } from '../../hooks'

const HeroHome = ({
  ctaA,
  ctaB,
  imageA,
  imageB,
  videoA,
  videoB,
  headingA,
  headingB,
  descriptionA,
  descriptionB,
}) => {
  const [startAnimation, setStartAnimation] = useState(false)
  const [initAnimation, setInitAnimation] = useState(true)
  const { introEnded } = useIntroEnded()

  const { width } = useWindowDimensions() // hook to get dynamic height/width
  const [isViewportMobile, setViewPortMobile] = useState(width <= 768) // Current Page Index

  useEffect(() => {
    setViewPortMobile(width <= 768)
  }, [width])

  const cardsData = [
    {
      cta: ctaA,
      image: imageA,
      video: videoA,
      heading: headingA,
      description: descriptionA,
    },
    {
      cta: ctaB,
      image: imageB,
      video: videoB,
      heading: headingB,
      description: descriptionB,
    },
  ]

  useEffect(() => {
    if (introEnded) {
      setStartAnimation(true)
      setTimeout(() => {
        setInitAnimation(false)
      }, 300)
    }

    return () => {
      setStartAnimation(false)
    }
  }, [introEnded])

  return (
    <SectionContainer className='pb-30 pt-70 bg-secondary md:pb-120 md:pt-160'>
      <h1 className='sr-only' tabIndex='-1'>
        Welcome to Big-D McAlvain
      </h1>
      <div className={'p-16 md:p-32'}>
        <GridContainer className={clsx(Styles.grid, 'overflow-hidden')}>
          <div
            className={
              'col-span-9 mt-32 md:mt-0 md:aspect-w-4 md:aspect-h-1 lg:aspect-w-12 lg:aspect-h-5'
            }>
            <div className={clsx('flex flex-col w-full h-full md:flex-row')}>
              {cardsData &&
                cardsData?.map((card, i) => (
                  <div
                    className={clsx(
                      Styles.cardContainer,
                      'relative',
                      `card-${i}`,
                      'md:first:pb-0 md:first:pt-0',
                      'md:last:mt-0 last:mt-24',
                      'md:first:pr-8 md:last:pl-8',
                      'overflow-hidden'
                    )}
                    key={card.heading}>
                    <Link href={card.cta?.url[0]?.uri} prefetch={false}>
                      <a
                        className={clsx(
                          Styles.card,
                          i === 0 && Styles.card_left,
                          i === 1 && Styles.card_right,
                          'flex flex-col items-center justify-start h-full'
                        )}>
                        {/* Content - Image */}
                        <div className={'relative w-full h-full z-0'}>
                          {(isViewportMobile ? true : !card.video) && (
                            <CustomImage
                              priority={true}
                              dataPreload={true}
                              src={card.image?.sourceUrl}
                              alt={card.image?.altText}
                              imageClassName={clsx(
                                'h-full transform transition duration-1000 ease-out-bigd',
                                'bg-image',
                                startAnimation && 'scale-110',
                                !startAnimation && 'scale-125'
                              )}
                              aspectRatio='aspect-h-1 aspect-w-2'
                            />
                          )}
                          {card.video && !isViewportMobile && (
                            <div
                              className={clsx(
                                'hidden justify-center h-full overflow-hidden transform transition duration-1000 ease-out-bigd md:flex'
                              )}>
                              <video
                                width='1920'
                                height='960'
                                autoPlay
                                muted
                                loop
                                className='absolute w-full max-w-none h-full bg-background object-cover'>
                                <source src={card.video} type='video/mp4' />
                              </video>
                            </div>
                          )}
                        </div>

                        {/* Content - Copy */}
                        <div
                          className={clsx(
                            'absolute bottom-0 left-0 right-0 top-0 flex flex-col justify-end p-20 overflow-hidden md:p-24',
                            i === 0 && 'items-start',
                            i === 1 && 'md:items-end',
                            'z-10'
                          )}>
                          <div className={clsx(Styles.description, 'mb-20 md:mb-24')}>
                            <Paragraph
                              text={card.description}
                              overrideClassName={clsx(
                                'text-primary text-sm-A -tracking-3',
                                i === 1 && 'md:text-right',
                                'transition duration-500 delay-500 ease-out-bigd',
                                startAnimation && 'opacity-100',
                                !startAnimation && 'opacity-0'
                              )}
                            />
                          </div>
                          <Heading
                            semantics='h2'
                            text={card?.heading}
                            className={clsx(
                              Styles.heading,
                              '-ml-4 text-primary -tracking-4 uppercase md:-ml-6 md:mr-6 md:-tracking-7',
                              'transition duration-1000 delay-1000 ease-out-bigd',
                              // special case on home hero
                              'h2-scale-down-B', // same font size between card titles
                              'text-4xl-D md:text-6xl-C', // desktop
                              'z-10',
                              initAnimation && Styles.headingInit
                            )}
                          />
                        </div>
                        <div
                          className={clsx(
                            'absolute bottom-0 left-0 right-0 top-0 bg-background transition duration-500 ease-out-bigd',
                            startAnimation && 'opacity-0',
                            !startAnimation && 'opacity-100'
                          )}></div>
                      </a>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </GridContainer>
      </div>
    </SectionContainer>
  )
}

export default React.memo(HeroHome)
