import clsx from 'clsx'

import { Heading, SubHeading, CustomImage, GridContainer, LinkWrapper } from '../../index'
import Styles from './NewsRecommendationCard.module.scss'

const NewsRecommendationCard = ({ post, subHeading, href }) => {
  return (
    <div className={clsx(Styles.card, ' border-b border-image cursor-pointer')}>
      <LinkWrapper href={href}>
        <GridContainer className={clsx(Styles.container, 'py-30 md:min-h-300')}>
          <div className='col-span-2 self-center mb-20 md:col-span-2.5 md:mb-0'>
            <CustomImage
              className='w-full'
              src={post?.signaturePostNewsFields?.headerImage?.sourceUrl}
              alt={post?.signaturePostNewsFields?.headerImage?.altText}
              aspectRatio='aspect-w-10.5 aspect-h-6.5'
              imageClassName='bg-secondary'
            />
          </div>

          <div className={clsx('col-span-4 md:col-start-3 md:mb-25 md:mt-15')}>
            <SubHeading
              text={subHeading}
              className={clsx(Styles.subheading, 'pb-20 md:pb-35 md:pt-40')}
            />

            <Heading
              semantics='h2'
              text={post.title}
              className='text-primary text-2xl-F -tracking-5.5 uppercase md:text-4xl-B'
            />
          </div>
        </GridContainer>
      </LinkWrapper>
    </div>
  )
}

export default NewsRecommendationCard
