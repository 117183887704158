import Head from 'next/head'

const GlobalMeta = () => (
  <Head>
    {/* Add your global font imports here */}
    <link
      rel='preload'
      href='/fonts/GoodSans-Regular.woff2'
      as='font'
      type='font/woff2'
      crossOrigin='anonymous'
    />
    <link
      rel='preload'
      href='/fonts/MonumentGrotesk-Regular.woff2'
      as='font'
      type='font/woff2'
      crossOrigin='anonymous'
    />

    {/* PWA */}
    <meta name='application-name' content='BigD - McAlvain' />
    <meta name='apple-mobile-web-app-capable' content='yes' />
    <meta name='apple-mobile-web-app-status-bar-style' content='default' />
    <meta name='apple-mobile-web-app-title' content='BigD - McAlvain' />
    <meta name='format-detection' content='telephone=no' />
    <meta name='mobile-web-app-capable' content='yes' />
    <meta name='msapplication-config' content='/icons/browserconfig.xml' />
    <meta name='msapplication-TileColor' content='#1e3040' />
    <meta name='msapplication-tap-highlight' content='no' />
    <meta name='theme-color' content='#1e3040' />

    <link rel='apple-touch-icon' href='/images/signature-192.png' />
    <link rel='apple-touch-icon' sizes='192x192' href='/images/signature-192.png' />
    <link rel='apple-touch-icon' sizes='384x384' href='/images/signature-384.png' />
    <link rel='apple-touch-icon' sizes='512x512' href='/images/signature-512.png' />

    <link rel='icon' type='image/png' sizes='32x32' href='/images/signature-32.png' />
    <link rel='icon' type='image/png' sizes='16x16' href='/images/signature-16.png' />
    <link rel='manifest' href='/manifest.json' />
    <link rel='mask-icon' href='/images/signature-192.png' />
    <link rel='shortcut icon' href='/images/signature-192.png' />
  </Head>
)

export default GlobalMeta
