import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { getEmbedUrlAndPlatform } from '../../utils'

import { Paragraph, Heading, BackgroundTintLayer, Button } from '../index'

import MenuClose from '../../../public/icons/menu-close.svg'
import Arrow from '../../../public/icons/arrow-down-no-border.svg'

import s from './Modal.module.scss'

const VideoGridModal = ({
  id = 'videoGridModal', // unique required
  isModalActive,
  onClose,
  onClickNext,
  onClickPrev,
  backgroundColor,
  url,
  modalData,
}) => {
  const [hidden, setHidden] = useState(true)
  const [embededVideoUrl, setEmbededVideoUrl] = useState(null)

  useEffect(() => {
    if (isModalActive && typeof window !== 'undefined') {
      const { embededUrl } = getEmbedUrlAndPlatform(url) // if vimeo needed, see MediaVideo component
      setEmbededVideoUrl(embededUrl)
    }

    if (!isModalActive) {
      setHidden(true)
    } else {
      const timer = setTimeout(() => {
        setHidden(false)
      }, 300)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isModalActive, url])

  const closeModal = () => {
    onClose()
  }

  return (
    <div
      className={clsx(
        'fixed z-40 left-0 top-0 w-screen h-full bg-transparent',
        isModalActive && 'items-center justify-center md:flex ',
        !isModalActive && 'hidden'
      )}>
      {/* Modal Background Tint */}
      <BackgroundTintLayer
        onClick={closeModal}
        active={isModalActive}
        backgroundColor={backgroundColor}
        opacity='opacity-60'
      />

      {/* Modal */}
      <div
        className={clsx(
          s.modalVideoGrid,
          'transition duration-300',
          hidden ? 'opacity-0' : 'opacity-100'
        )}
        onClick={event => event.stopPropagation()}
        role='dialog'>
        <div className={s.buttonsWrapper}>
          <div className={s.buttonNavigation}>
            <button onClick={onClickPrev} aria-label='Prev' className={s.arrowButton}>
              <Arrow />
            </button>
            <button onClick={onClickNext} aria-label='Next' className={s.arrowButton}>
              <Arrow />
            </button>
          </div>
          <button
            onClick={closeModal}
            aria-label='Close'
            className={clsx(
              s.button,
              'relative',
              'flex items-center justify-center',
              'rounded-full focus-visible:outline-black focus:outline-none cursor-pointer',
              'w-32 h-32 md:w-38 md:h-38',
              'text-accent',
              'h-contrast'
            )}>
            <MenuClose className='w-14 h-14 fill-current md:w-14 md:h-14' />
          </button>
        </div>

        {isModalActive && (
          <div className={s.videoModal}>
            <div className={clsx(s.videoWrapper, 'bg-image')}>
              { embededVideoUrl &&
                <iframe
                  id={id}
                  src={embededVideoUrl}
                  className='aspect-video w-full'
                  title=''
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen></iframe>
              }
            </div>
            <div className={s.dataWrapper}>
              <Heading
                semantics='h2'
                text={modalData?.title}
                className='mb-40 text-secondary text-2xl-F -tracking-5.5 uppercase md:text-3xl'
              />
              <div className={s.dataColumns}>
                <div className={s.column}>
                  { modalData?.textColumn1 && 
                    <Paragraph text={modalData?.textColumn1.indexOf('<p>') > -1 ? modalData?.textColumn1 : `<p>&nbsp;</p>${modalData?.textColumn1}`} />
                  }
                </div>

                <div className={s.column}>
                  { modalData?.textColumn2 && 
                    <Paragraph text={modalData?.textColumn2.indexOf('<p>') > -1 ? modalData?.textColumn2 : `<p>&nbsp;</p>${modalData?.textColumn2}`} />
                  }
                </div>
              </div>

              <div className='right-30 top-40 mt-40 md:absolute md:mt-0'>
                <Button
                  href={modalData?.appUrl}
                  text='Submit application here'
                  buttonColor='primary'
                  className='px-10 py-10 text-base-A md:px-20 md:py-15'
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default VideoGridModal
