import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const BASE_ERROR_MESSAGE_CLASSNAMES = [
  'row-start-7',
  'md:col-span-1',
  'mt-20',
  'md:mt-0',
  'text-base-A',
  '-tracking-3',
  'md:text-2xl-C',
];

const UploadFormErrorMessages = ({
  submitAttempt,
  isFormValid,
  submitSuccessMessage,
  submitErrorMessage,
}) => {
  const getErrorMessage = () => {
    if (submitAttempt && !isFormValid) {
      return "One or more fields have an error. Please check and try again.";
    }
    if (submitErrorMessage) {
      return submitErrorMessage;
    }
    return null;
  };

  const getSuccessMessage = () => {
    if (submitSuccessMessage) {
      return submitSuccessMessage;
    }
    return null;
  }

  const errorMessage = getErrorMessage();
  const successMessage = getSuccessMessage();

  if (successMessage) {
    return (
      <p className={clsx(...BASE_ERROR_MESSAGE_CLASSNAMES, 'text-green-500')}>
        {submitSuccessMessage}
      </p>
    );
  } else if (errorMessage) {
    return (
      <p className={clsx(...BASE_ERROR_MESSAGE_CLASSNAMES, 'text-red-500')}>
        {errorMessage}
      </p>
    );
  }
  else {
    return <div/>;
  }
};

export default UploadFormErrorMessages;
