import clsx from 'clsx'
import React, { useState } from 'react'

import {
  Modal,
  Heading,
  CopyGroup,
  Container,
  Paragraph,
  SliderCard,
  SubHeading,
  GridContainer,
  SectionContainer,
} from '../index'
import { isObjectEmpty } from '../../utils'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MediaGridCards = ({
  className = '',
  heading,
  subHeading,
  description,
  cardsInfoArray,
  modals = false,
  headingExtraIndent = false,
}) => {
  const [isModalActive, setModalActive] = useState(false)
  const [activeModalInfo, setActiveModalInfo] = useState({})

  const headingIndent = headingExtraIndent
    ? 'md:col-span-7 md:col-start-2'
    : 'md:col-span-8.5 md:col-start-0.5'

  const showModal = event => {
    const { name } = event?.target
    const bio = cardsInfoArray?.filter(i => i?.cta?.name === name)?.[0]

    setActiveModalInfo(bio)
    setModalActive(true)
  }

  const closeModal = event => {
    event.stopPropagation()
    setModalActive(false)
    setActiveModalInfo({})
  }

  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <SectionContainer className={clsx('pb-100 pt-80 md:pb-150', className)}>
      <Container className='pb-0'>
        {/* Headings */}

        <GridContainer className='pb-0 md:pb-40'>
          <div className={`col-span-4 ${headingIndent}`}>
            <SubHeading
              text={subHeading}
              className='place-self-start pb-40 md:pb-35'
              onScrollAnimation={true}
            />

            <Heading
              semantics='h2'
              text={heading}
              className={clsx(
                'mb-20 break-words text-4xl-D -tracking-6 uppercase md:-ml-10 md:text-left md:text-8xl-A',
                'md:mb-0',
                hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
              )}
              indent='30'
              onScrollAnimation={true}
            />
          </div>
        </GridContainer>

        {description && (
          <GridContainer className='pb-30 md:pb-0'>
            <div className='col-span-4 md:col-span-8 md:col-start-1'>
              <Paragraph text={description} className='md:w-350' />
            </div>
          </GridContainer>
        )}

        {/* Leaders - 3x */}
        <GridContainer>
          <div
            className={clsx(
              'relative flex flex-wrap col-span-9',
              '-mx-16', // bleed both sides on mobile but not on desktop
              'flex-wrap md:px-30 '
            )}>
            <div className={clsx('flex flex-wrap col-span-9')}>
              {Array.isArray(cardsInfoArray)
                ? cardsInfoArray?.map((i, index) => {
                    const hasModalDetail = !isObjectEmpty(i?.cta) && i?.cta?.type === 'modal'

                    return (
                      <SliderCard
                        key={index}
                        image={i?.image}
                        className={clsx(
                          'sliderCardImage px-16 w-full min-w-full',
                          'md:px-30 md:w-1/3 md:min-w-auto',
                          'relative mt-20 py-30 md:mt-90'
                        )}
                        overrideCopyFromOutside={true}
                        customImageAspectRatio='aspect-h-6 aspect-w-5 md:aspect-h-10 md:aspect-w-9'
                        drag={false}>
                        <CopyGroup
                          heading={i?.title}
                          subHeading={i?.headline}
                          description={hasModalDetail ? i.shortBio : i?.description}
                          className={'pt-40 md:pl-40'}
                          paragraphClassName={'pr-30 md:min-h-140'}
                          cta={
                            hasModalDetail
                              ? { ...i?.cta, clickHandler: showModal } // only add clickhandler to type modals
                              : {}
                          }
                        />
                      </SliderCard>
                    )
                  })
                : null}
            </div>

            {modals ? (
              <Modal
                isModalActive={isModalActive}
                closeModal={closeModal}
                image={activeModalInfo?.secondaryImage ? activeModalInfo?.secondaryImage : activeModalInfo?.image}
                heading={activeModalInfo?.title}
                subHeading={activeModalInfo?.headline}
                description={activeModalInfo?.description}
                socialLinks={activeModalInfo?.socialLinks}
              />
            ) : null}
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default MediaGridCards
