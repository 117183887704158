import clsx from 'clsx'
import React, { useRef, useState, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}
import { useSpring, animated } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import { useGesture } from '@use-gesture/react'
import { ResizeObserver } from '@juggle/resize-observer'
import { useWindowDimensions } from '../../hooks'

import {
  Modal,
  Heading,
  CopyGroup,
  Container,
  Paragraph,
  SliderCard,
  SubHeading,
  GridContainer,
  SectionContainer,
} from '../index'
import { isObjectEmpty } from '../../utils'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MediaSliderCards = ({
  className = '',
  heading,
  subHeading,
  description,
  sliderCardsInfoArray, // all team-information passed via this array to handle Modal appropriately
  modals = false,
  headingExtraIndent = false,
}) => {
  const Slider = sliderCardsInfoArray.length > 3 ? true : false

  const GridRef = useRef()
  const [isModalActive, setModalActive] = useState(false)
  const [activeModalInfo, setActiveModalInfo] = useState({})
  const q = gsap.utils.selector(GridRef)
  const tl = useRef()

  useEffect(() => {
    !Slider &&
      ScrollTrigger.matchMedia({
        // desktop
        '(min-width: 768px)': function () {
          q('.sliderCardImage')[1] &&
            q('.sliderCardImage')[2] &&
            (tl.current = gsap
              .timeline({
                scrollTrigger: {
                  trigger: GridRef?.current,
                  start: '0 90%',
                  end: '50% 50%',
                  scrub: true,
                  invalidateOnRefresh: true,
                },
              })
              .addLabel('init')
              .fromTo(
                q('.sliderCardImage')[1],
                {
                  y: 100,
                },
                {
                  y: 0,
                },
                'init'
              )
              .fromTo(
                q('.sliderCardImage')[2],
                {
                  y: 200,
                },
                {
                  y: 0,
                },
                'init'
              ))

          return function () {
            tl?.current?.kill()
            gsap.set(q('.sliderCardImage'), { clearProps: true })
          }
        },
      })
  }, [])

  const headingIndent = headingExtraIndent
    ? 'md:col-span-7 md:col-start-2'
    : 'md:col-span-8.5 md:col-start-0.5'

  const showModal = event => {
    const { name } = event?.target
    const bio = sliderCardsInfoArray?.filter(i => i?.cta?.name === name)?.[0]

    setActiveModalInfo(bio)
    setModalActive(true)
  }

  const closeModal = event => {
    event.stopPropagation()
    setModalActive(false)
    setActiveModalInfo({})
  }

  const [drag, setDrag] = useState(true)

  const [styles, updateStyles] = useSpring(() => ({
    x: 0,
    config: { tension: 150 },
  }))

  const { width: windowsWidth } = useWindowDimensions()

  const [containerRef, { width, left, top }] = useMeasure({
    scroll: true,
    polyfill: ResizeObserver,
  })

  const [gridRef, { width: widthGrid }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const runSprings = ox => {
    updateStyles.start(() => {
      return {
        x: ox,
      }
    })
  }

  const bind = useGesture(
    {
      onDrag: ({ down, event, offset: [ox] }) => {
        down && event.preventDefault()
        runSprings(ox)
        if (drag) {
          setDrag(false)
        }
      },
    },
    {
      drag: {
        bounds: {
          left: -(width - widthGrid),
          right: 0,
        },
        rubberband: true,
        filterTaps: true,
      },
    }
  )

  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <SectionContainer className={clsx('pb-100 pt-80 md:pb-150', className)}>
      <Container className='pb-0'>
        {/* Headings */}

        <GridContainer className='pb-0 md:pb-40'>
          <div className={`col-span-4 ${headingIndent}`}>
            <SubHeading
              text={subHeading}
              className='place-self-start pb-40 md:pb-35'
              onScrollAnimation={true}
            />

            <Heading
              semantics='h2'
              text={heading}
              className={clsx(
                'mb-20 break-words text-4xl-D -tracking-6 uppercase md:-ml-10 md:text-left md:text-8xl-A',
                'md:mb-0',
                hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
              )}
              indent='30'
              onScrollAnimation={true}
            />
          </div>
        </GridContainer>

        {description && (
          <GridContainer className='pb-60 md:pb-100'>
            <div className='col-span-4 md:col-span-8 md:col-start-1'>
              <Paragraph text={description} className='md:w-350' />
            </div>
          </GridContainer>
        )}

        {/* Leaders - 3x1 */}
        <GridContainer>
          {/* Horizontal Scroll on mobile */}
          <div
            {...(Slider ? bind() : null)}
            ref={Slider ? gridRef : GridRef}
            style={{ cursor: Slider ? 'grab' : 'auto', touchAction: 'auto' }}
            className={clsx(
              'relative flex col-span-9',
              'scrollbar-hide overflow-x-scroll overflow-y-hidden md:overflow-visible',
              '-mx-16', // bleed both sides on mobile but not on desktop
              !Slider && 'md:px-30 md:flex-wrap '
            )}>
            {/* Horizontal Scroll on mobile */}
            <animated.div
              onDragStart={e => e.preventDefault()}
              ref={containerRef}
              style={{
                ...(Slider ? styles : null),
                willChange: 'transform',
                touchAction: Slider ? 'pan-y' : 'auto',
                width: Slider ? 'min-content' : 'auto',
              }}
              className={clsx(
                'flex col-span-9',
                Slider &&
                  ('select-none',
                  '-mr-16 md:-mr-50', // bleed on the right
                  'md:-ml-50 md:pl-100') // bleed & pad on the left
              )}>
              {Array.isArray(sliderCardsInfoArray)
                ? sliderCardsInfoArray?.map((i, index) => {
                    const hasModalDetail = !isObjectEmpty(i?.cta) && i?.cta?.type === 'modal'

                    return (
                      <SliderCard
                        key={index}
                        image={i?.image}
                        className={clsx(
                          'sliderCardImage px-16 w-5/6 min-w-80vw',
                          Slider ? 'md:min-w-450 md:max-w-450' : 'md:min-w-auto md:w-1/3 md:px-30'
                        )}
                        overrideCopyFromOutside={true}
                        customImageAspectRatio='aspect-h-6 aspect-w-5 md:aspect-h-10 md:aspect-w-9'
                        drag={Slider ? drag : false}>
                        <CopyGroup
                          heading={i?.title}
                          subHeading={i?.headline}
                          description={hasModalDetail ? i.shortBio : i?.description}
                          className={clsx('pt-40', Slider ? 'md:pl-20' : 'md:pl-40')}
                          paragraphClassName={clsx(
                            Slider ? 'md:pr-70' : 'md:pr-50',
                            'pr-30 md:min-h-140'
                          )}
                          cta={
                            hasModalDetail
                              ? { ...i?.cta, clickHandler: showModal } // only add clickhandler to type modals
                              : {}
                          }
                        />
                      </SliderCard>
                    )
                  })
                : null}
            </animated.div>

            {modals ? (
              <Modal
                isModalActive={isModalActive}
                closeModal={closeModal}
                // image={i?.image} // imp: don't to this. It wont work - closures
                image={activeModalInfo?.image}
                heading={activeModalInfo?.title}
                subHeading={activeModalInfo?.headline}
                description={activeModalInfo?.description}
                socialLinks={activeModalInfo?.socialLinks}
              />
            ) : null}
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default MediaSliderCards
