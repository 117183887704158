import clsx from 'clsx'
import { forwardRef } from 'react'

const Dash = forwardRef(({ variation = 'long', className = '' }, ref) => {
  const longVariation = variation === 'long'
  return (
    <div
      className={clsx(
        'relative flex-shrink-0 border-b border-current',
        variation === 'long' && 'w-64 mr-8 pt-0',
        variation === 'medium' && 'w-40 mr-10 pt-0',
        variation === 'short' && 'w-20 mr-10 pt-0',
        variation === 'arrow' && 'w-60 ml-15 mr-10 pt-0',
        className
      )}
      ref={ref}>
      {variation === 'arrow' && (
        <div className='absolute -left-3 -top-5 w-8 h-11'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.5 11.5'>
            <path fill='none' stroke='currentColor' d='m5.5 1.5-4 4 4 4' />
          </svg>
        </div>
      )}
    </div>
  )
})

export default Dash
