import { useRef, useEffect } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import { IMG_LAZY_LOAD_THRESHOLD } from '../../utils/siteConfig'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

// using layout-fill in image & aspect-ratio plugin in the wrapper to set height & width
const CustomImage = ({
  src,
  alt,
  image, // alternatively pass entire image object
  caption,
  captionClassName,
  className, // wrapper class name - pass width here
  aspectRatio, // pass aspect ratio here
  imageClassName,
  objectProp = 'object-cover object-center',
  dataPreload = false, // defaults
  priority = false, // defaults
  sizes = '100vw',
  reference,
  onClick,
}) => {
  // check image data in image object prop as well
  const _src = src ? src : image?.sourceUrl
  const _alt = alt ? alt : image?.altText
  const _caption = caption ? caption : image?.caption
  const figureRef = useRef()

  useEffect(() => {
    /*
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: figureRef?.current,
        start: '0 85%',
      },
    });
    tl.fromTo(
      figureRef?.current,
      { opacity: 0 },
      { opacity: 1, duration: .5 }
    )
    */
  }, [])

  if (!_src) return null
  return (
    <div className={className} ref={reference} onClick={onClick}>
      <figure
        className={clsx('w-full h-auto', aspectRatio ? aspectRatio : 'aspect-w-1 aspect-h-1')}
        ref={figureRef}
      >
        {_src ? (
          <Image
            src={_src}
            alt={_alt}
            layout='fill' // imp
            sizes={sizes}
            priority={priority}
            data-preload={dataPreload}
            className={clsx('w-full h-auto', imageClassName, objectProp)}
            lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
          />
        ) : null}

        {_caption ? (
          <figcaption aria-hidden='true' className={'hidden'}>
            {_caption}
          </figcaption>
        ) : null}
      </figure>

      {_caption ? (
        <div
          className={clsx(
            'my-8 text-right text-xs-caption -tracking-3 uppercase md:my-10',
            captionClassName
          )}
        >
          {_caption}
        </div>
      ) : null}
    </div>
  )
}

export default CustomImage
