import clsx from 'clsx'
import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import { Container, CustomImage, GridContainer, SectionContainer } from '../index'

import Styles from './MediaImage.module.scss'

const MediaImage = ({ image, animate = true, variant = '' }) => {
  const imageRef = useRef()
  const q = gsap.utils.selector(imageRef)
  const tl = useRef()

  useEffect(() => {
    animate && imageRef?.current &&
      (tl.current = gsap
        .timeline({
          defaults: { duration: 1, ease: 'bigd-ease-out' },
          scrollTrigger: {
            trigger: imageRef?.current,
            scrub: true,
            invalidateOnRefresh: true,
          },
        })
        .fromTo(
          q('.customImage > figure'),
          {
            scale: 1.2,
            yPercent: 10,
          },
          {
            scale: 1.2,
            yPercent: -10,
          }
        ))
  }, [])

  return (
    <SectionContainer className='mb-0 md:mb-150'>
      <Container>
        <GridContainer>
          <div
            className={clsx(
              variant === 'fullscreen'
                ? 'col-span-full'
                : 'md:col-start-1 md:col-span-7 col-start-0.5 col-span-3' // variant wrapped
            )}
          >
            <div ref={imageRef}>
              <CustomImage
                src={image?.sourceUrl}
                alt={image?.altText}
                objectProp='object-cover'
                aspectRatio='aspect-w-16 aspect-h-9'
                className={clsx('customImage -mx-32 overflow-hidden md:-mx-30', Styles.image)}
                captionClassName='pr-16 md:pr-0'
              />
            </div>
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default MediaImage
